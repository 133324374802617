import { useEffect, useState } from "react";
import { UserAccess } from "../../models/userAccess.model";
import { UserAccessService } from "../../services/UserAccess/userAccess.service";
import { LocalStorageHelper } from "../utils/localStorageHelper";
import { LocalStorage } from "../../enums/localStorage.enum";
import { isEqualIgnoreCase } from "../utils/isEqualIgnoreCase";

const useUserAccess = () => {
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const [userAccess, setUserAccess] = useState<UserAccess>();
  const [userRoles, setUserRoles] = useState<UserAccess[]>();
  const { getUserAccess, loading } = UserAccessService();
  useEffect(() => {
    const handleUserAccess = async () => {
      const userRole = await getUserAccess();
      if (userRole) {
        setUserRoles(userRole);
        const currentUser = userRole.find((access) =>
          isEqualIgnoreCase(access?.name, employee?.roleName)
        );
        setUserAccess(currentUser);
        LocalStorageHelper.setItem(
          LocalStorage.CURRENT_USER_ACCESS,
          currentUser
        );
      }
    };

    handleUserAccess();
  }, []);

  return { userAccess, userRoles, loading };
};

export default useUserAccess;

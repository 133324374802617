import { alias, list, primitive, serializable } from 'serializr';
export class DownloadReport {
    @serializable(alias("branch", list(primitive())))
    branch?: number[] = [];

    @serializable(alias("disbursement_date"))
    disbursementDate?: string

    @serializable
    date?: string

    @serializable(alias("from_date"))
    fromDate?: string

    @serializable(alias("to_date"))
    toDate?: string

    @serializable(alias("report_type"))
    reportType?: string
}
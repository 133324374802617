export const precloseConstants = {
    FORM_FIELDS: {
        PRECLOSE_DATE: {
            LABEL: "Preclose Date",
            PLACEHOLDER: "Select Preclose Date",
            FIELD: "precloseDate",
            VALIDATION: "Preclose Date is required"
        },
        PRECLOSE_AMOUNT: {
            LABEL: "Preclose Amount",
            PLACEHOLDER: "",
            FIELD: "precloseAmount"
        },
        AMOUNT_COLLECTED: {
            REQUIRED_VALIDATION: "Amount Collected is required",
            MATCH_VALIDATION: "Amount Collected and Preclose Amount should be the same"
        }
    }
}
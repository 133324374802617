import { alias, primitive, serializable } from "serializr";

export class LoanStatusModel {
  @serializable(alias("assigned_at", primitive()))
  assignedAt?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("requested_loan_amt", primitive()))
  requestedLoanAmt?: string;

  @serializable(alias("updated_at"))
  updatedAt?: string;
}

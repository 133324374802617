import { Employee } from "../../../../../../models/employee.model";
import { UserStatusLabel } from '../../../../../../enums/userStatus';

export const getEmployeeStatus = (employee?: Employee): string => {
    if (!employee) return "";
    if (!employee.isActive && !employee.isFrozen) {
      return UserStatusLabel.IN_ACTIVE;
    } else if (employee.isActive && !employee.isFrozen) {
      return UserStatusLabel.ACTIVE;
    } else if (!employee.isActive && employee.isFrozen) {
      return UserStatusLabel.FROZEN;
    }
    return "Unknown Status";
  };
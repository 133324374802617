import React, { useState } from "react";
import { DatePicker } from "antd";
import { Field, useField, useFormikContext } from "formik";
import Error from "../Error";
import "./datePickerField.scss";
import moment, { Moment } from "moment";
import { dateFormats } from "../../../enums/dateFormats.type";

interface DatePickerFieldProps {
  name: string;
  title?: string;
  setFieldValue?: Function;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  value?: any;
  onChange?: (date: any, dateString: any) => void;
  onBlur?: () => void;
  disabledDate?: (date: Moment) => boolean;
  picker?: any;
  format?: string;
  type?: "rangePicker" | "date";
  error?: string;
  maxMonths?: number;
}

function DatePickerField(props: DatePickerFieldProps) {
  const { RangePicker } = DatePicker;
  const {
    title,
    name,
    format,
    picker,
    disabledDate,
    setFieldValue,
    defaultValue,
    onChange,
    value,
    onBlur,
    disabled,
    type,
    maxMonths
  } = props;
  const formikContext = useFormikContext();

  const handleChange = (date: any, dateString: any) => {
    if (setFieldValue) {
      if (type === "date") {
        setFieldValue(name, date?.format(dateFormats.YEAR_FIRST));
      } else {
        setFieldValue(name, date ? [
          date[0]?.format(dateFormats.YEAR_FIRST),
          date[1]?.format(dateFormats.YEAR_FIRST)
        ] : null);
      }
    }
    if (format) {
      if(setFieldValue)
      setFieldValue(name, date?.format(format))
    }
    formikContext.setFieldTouched(name, true, false);
  };

  const [selectedFromDate, setSelectedFromDate] = useState<Moment | null>(null);
  const [, setSelectedToDate] = useState<Moment | null>(null);

  const handleCalenderChange = (dates: (Moment | null)[] | null) => {
    setSelectedFromDate(dates?.[0] ?? null);
    setSelectedToDate(dates?.[1] ?? null);
  };
  

  const handleDisabledDate = (current: Moment) => {
    if (!selectedFromDate || !maxMonths) return false;
    const fromDate = selectedFromDate;
    const toDate = fromDate.clone().add(maxMonths, "months").endOf("month");
    
    return current.isBefore(fromDate, "day") || current.isAfter(toDate, "day");
  };

  const handleBlur = () => {
    formikContext.setFieldTouched(name, true);
    if (onBlur) {
      onBlur();
    }
  };

  const [, meta] = useField(name);
  return (
    <div className="date-picker-container">
    <Field name={name}>
      {({ form: { touched, errors } }: any) => (
        <div className="date-picker-field">
          {title && (
            <div className="date-picker-field__title mt-1">{title}</div>
          )}
          {type === "rangePicker" ? (
            <RangePicker
              className={`date-picker-${name}`}
              disabled={disabled}
              onChange={onChange || handleChange}
              disabledDate={disabledDate ? disabledDate : handleDisabledDate}
              value={
                value ? value : defaultValue ? moment(defaultValue) : undefined
              }
              format={format || dateFormats.YEAR_FIRST}
              name={name}
              onBlur={handleBlur}
              getPopupContainer={(elem) => elem.parentElement || elem}
              onCalendarChange={handleCalenderChange}
            />
          ) : (
            <DatePicker
              className={`date-picker-${name}`}
              disabled={disabled}
              picker={picker}
              onChange={onChange || handleChange}
              disabledDate={disabledDate ? disabledDate : undefined}
              value={
                value ? value : defaultValue ? moment(defaultValue) : undefined
              }
              format={format || dateFormats.DATE_FIRST}
              name={name}
              onBlur={handleBlur}
              getPopupContainer={(elem) => elem.parentElement || elem}
            />
          )}
        </div>
      )}
    </Field>
    {meta.error && meta?.touched && <Error message={meta.error} />}
     </div>
  );
}

export default DatePickerField;
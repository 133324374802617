import { UserStatus } from "../../enums/userStatus";

export const userActiveStatusOption = [
    {
      label: "Active",
      value: UserStatus.ACTIVE,
    },
    {
      label: "Inactive",
      value: UserStatus.INACTIVE,
    },
    {
        label: "Freeze",
        value: UserStatus.FREEZED
    }
  ];
import React, { useEffect, useState } from "react";
import Back from "../../../shared/components/Back";
import "./creditReport.scss";
import { Col, Row, Tabs } from "antd";
import { TabsProps } from "../../../shared/types/Tabs.type";
import { useParams } from "react-router-dom";
import { Loan, LoanParams } from "../../../models/loan.model";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import KeyFactorsComponent from "../KeyFactors";
import TotalAccounts from "../Total Accounts";
import CrifPersonalDetails from "../PersonalDetails";
import ReactSpeedometer from "react-d3-speedometer";
import { LoanService } from "../../../services/Loan/loan.service";
import { CrifReport } from "../../../models/creditReport.model";
import CreditEnquiriesComponent from "../CreditEnquiries";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { getCrifRating } from "../../../shared/utils/creditReportUtil";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import AppLoader from "../../../shared/components/AppLoader";
import { CreditReportKeyFactorLabels } from "../KeyFactors/IndividualKeyFactor/constants";

const CrifReportComponent = () => {
  const { loanId } = useParams();

  const { updateParams } = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const { getCrifReport, getLoan } = LoanService()
  const [crifData, setCrifData] = useState<CrifReport>();
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false)
  const tabExtraContent = (activeTabKey = activeTab) => {
    switch (activeTabKey) {
      case "3":
        return (
          <div className="d-flex">
            <p>
              {CreditReportKeyFactorLabels.INFORMATION_CONTRIBUTIONS}
            </p>
          </div>
        );
      case "4":
        return (
          <div className="d-flex">
            {/* commenting till confirmation from BE */}
            {/* <p>{CreditReportKeyFactorLabels.CREDIT_ENQUIRIES_REPORTED}</p> */}
          </div>
        );
      default:
        return <></>;
    }
  };

  const tabs: TabsProps[] = [
    {
      key: "1",
      label: "Key Factors",
      component: <KeyFactorsComponent loading={loading} loanId={loanId} data={crifData} />,
      hash: "#key-factors",
    },
    {
      key: "2",
      label: "Total Accounts",
      component: <TotalAccounts data={crifData} />,
      hash: "#total-accounts",
    },
    {
      key: "3",
      label: "Personal Details",
      component: <CrifPersonalDetails loanData={loan} data={crifData} />,
      hash: "#personal-details",
    },
    {
      key: "4",
      label: "Credit Enquiries",
      component: <CreditEnquiriesComponent data={crifData}/>,
      hash: "#credit-enquiries",
    },
  ];
  const [activeTab, setActiveTab] = useState("1");
  const getLoanDetails = async () => {
    setLoading(true);
    const details = await getLoan(Number(loanId))
    setLoan(details)
  }

  useEffect(() => {
    getLoanDetails();
  }, []);

  useEffect(() => {
    if (loan?.customer?.id) {
      getCrifData();
    }
  }, [loan]);

  const getCrifData = async () => {
    if (loan?.customer?.id) {
    setLoading(true)
    const crifData = await getCrifReport({ customerId: Number(loan?.customer.id), loanId: Number(loan?.id), initiate: false });
    setCrifData(crifData)
    LocalStorageHelper.setItem(LocalStorage.CRIF, crifData)
    setLoading(false)
    }
}

  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(activeKey);
  };

  return (
    <div>
      <Back />
      <div className="credit-report-wrapper">
      <AppLoader loading={loading}>
        <div className="card">
          <Row className="container">
          <Col className="speedometer-container">
            <ReactSpeedometer
              minValue={300}
              maxValue={900}
              value={crifData?.crifScore}
              maxSegmentLabels={1}
              needleColor="#77E6B5"
              needleTransitionDuration={1000}
              startColor="red"
              endColor="green"
              needleHeightRatio={0.3}
              segments={3333}
              width={150}
              height={120}
              forceRender={true}
              ringWidth={7}
              currentValueText=""
              textColor="#ffffff"
            />
            </Col>
            <Col span={13} className="score-container">
              <h1 className="credit-score">{fillEmptyData(crifData?.crifScore)}</h1>
              <h1 className="credit-rating">{fillEmptyData(getCrifRating(crifData?.crifScore))}</h1>
            </Col>
            <Col className="name-container text-left" span={4}>
              <p>Customer Name</p>
              <p>{loan?.customer.getName()}</p>
            </Col>
            <Col className="name-container text-left" span={3}>
              <p>Loan ID</p>
              <p>{loan?.code}</p>
            </Col>
            {/* remove till confirmation from BE  */}
            {/* <Col className="name-container" span={3}>
              <p>Last Update on</p>
              <p>{fillEmptyData(dateTimeFormatter(crifData?.lastUpdateOn,DateFormat.DATE))}</p>
            </Col> */}
          </Row>
        </div>
        <div className="tab-container">
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            tabBarExtraContent={tabExtraContent(activeTab)}
          >
            {tabs.map(({ component, ...tab }) => (
              <Tabs.TabPane tab={tab.label} key={tab.key}>
                {component}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
        </AppLoader>
      </div>
    </div>
  );
};

export default CrifReportComponent

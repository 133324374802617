import React, { useEffect, useState } from "react";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import {
  Loan,
  Loan as LoanModel,
  LoanParams,
} from "../../../models/loan.model";
import { generatePath, useNavigate } from "react-router-dom";
import useSorting from "../../../shared/hooks/useSorting";
import { TableConfig } from "../../../enums/tableConfig.type";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { DateRange } from "../../../shared/types/date.type";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { LoanStatus } from "../../../enums/loanStatus.type";
import Table from "../../../shared/components/Table";
import { Button, Checkbox, Col, Drawer, Modal, Row, Select, TableColumnsType, TableProps } from "antd";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { SorterResult } from "antd/lib/table/interface";
import FilterButtons from "../../../shared/components/FilterButtons";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import useDrawer from "../../../shared/hooks/useDrawer";
import LoanFilters from "../IndividualDisburesement/DisburesementFilters";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import "./approvedLoans.scss";
import DownloadIcon from "../../../Assets/images/downloadIcon.png";
import ChangeStatus from "../IndividualDisburesement/ChangeStatus";
import DisburseAmountForm from "../IndividualDisburesement/DisburseAmountForm";
import { DisbursementModel } from "../../../models/disbursement.model";
import CustomModal from "../../../shared/components/CustomModal";
import { modLoansTypes } from "../../../enums/modLoans.type";
import { ColumnProps } from "antd/lib/table";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { MODStatus } from "../../../enums/modStatusTags.type";
import { getFullName } from "../../../shared/utils/getFullName";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import MODUploadForm from "../../MODDocuments/MODUploadForm";
import { updateSelectedValue } from "../../../shared/utils/modFilter";
import changePreferredChannelOptions from "../../../shared/Constant/changePreferredChannelOptions";
import { BCStatusTags } from "../../../enums/currentStatusTags.type";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { renderModStatus } from "../../../shared/utils/renderModStatus";
import { organisationNames } from "../../../enums/organisationNames";
import { Process } from "../../../enums/process.enum";

const sortBy = {
  code: "code",
  centerName: "center_name",
  name: "customer_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  modStatus: "mod_status",
  organisations: "organisations",
  organizationName: "organisation_name",
  currentStatusTag: "current_status_tag"
};

const ApprovedLoans = () => {
  const {
    params: approvedParams,
    updateParams,
    getParams,
    handleSearch,
  } = useQueryParams({
    params: new LoanParams(),
  });

  const columns: ColumnProps<Loan>[] = [
    {
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true
    },
    {
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      render: (_, record) => record?.customer?.getName(),
      sorter: true
    },
    {
      title: "BC Status Tag",
      dataIndex: "currentStatusTag",
      render: (_, record) => {
        if(!record?.currentStatusTag) return " - "
        const className =
          "active-status--" + record.currentStatusTag?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getBCTagLabel() ?? "")}
          </span>
        );
      },
    },
    {
      title: "Security",
      dataIndex: "securedType",
      render: (security: string) => renderModStatus(security),
    },
    {
      title: "MOD Status",
      dataIndex: "modStatus",
      render: (_, record) => {
        if(!record?.modStatus) return " - "
        const className =
          "active-status--" + record.modStatus?.split("_")?.join("-");
        const isUpload = record.modStatus === MODStatus.UPLOAD_MOD || (record.modStatus === MODStatus.PENDING_ACKNOWLEDGEMENT && !record.modDocumentUrl)
        const isEmLoan = record.securedType === modLoansTypes.EQUAITABLE_MORTAGE
        return (
          <span
            className={`text-success text-capitalize active-status ${className} ${((isUpload && !isEmLoan) && "text-underline")}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isUpload && !isEmLoan) {
                setUploadModal(true)
                setModLoan(record)
              }
            }}
          >
            {removeUnderscore(record.getModStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      title: "MOD Document ID",
      dataIndex: "modDocumentId",
      render: (_, record: Loan) => record?.modDocumentId
    },
    {
      title: "Center",
      dataIndex: "centerName",
      sorter: true,
    },

    {
      title: "CRO",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (_, record) => getFullName(record?.createdBy),
    },
    {
      title: "Loan Type",
      dataIndex: "loanTypeName",
      sorter: true,
    },
    {
      title: "Batch Id",
      dataIndex: "batchId",
    },
    {
      title: "Branch Name",
      dataIndex: "branch",

      render: (_: string, record: Loan) => record?.branch?.name,
    },

    {
      title: "Total Amount",
      dataIndex: "requestedLoanAmt",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (_, record) => {
        const className = "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getLoanStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      title: "Preferred channel ",
      dataIndex: "organizationName",
      sorter: true
    },
  ];

  const navigate = useNavigate();
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.disbursement ?? []
  );
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loanTotalPage, setLoanTotalPage] = useState(1);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(columns);
  const [isLoanGenerated, setIsLoanGenerated] = useState(false);
  const [addedAmount, setAddedAmount] = useState<number>(0);
  const [selectedLoans, setSelectedLoans] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<Loan[]>([])
  const [, setDateRange] = useState<DateRange>();
  const [disbursedStep, setDisbursedStep] = useState<number>(0);
  const [disburseAmount, setDisburseAmount] = useState<number>(0);
  const [loanDetails, setLoanDetails] = useState(false)
  const [exportButtonVisible, setExportButtonVisible ] = useState(false)
  const [preferredChannelModal, setPreferredChannelModal] = useState(false)
  const [modLoan, setModLoan] = useState<Loan>()
  const [uploadModModal, setUploadModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState(approvedParams?.securityType);

  const { getLoans, loading, generateLoanReport, initiateDisburseStatus, changePreferredChannel } =
    LoanService();

  useEffect(() => {
    setColumnConfig(TableConfig.DISBURSEMENT, filteredColumns);
  }, [filteredColumns]);

  const fetchLoans = async () => {
    const updatedParams = {
      ...approvedParams,
      approved: true,
      searchText: approvedParams?.searchText,
    };

    const loanDetails = await getLoans(updatedParams);
    const val = loanDetails?.loans?.filter(
      (loan) => loan?.status === LoanStatus.APPROVED
    );
    if (val) setLoans(val);
    if (loanDetails?.meta) {
      loanDetails?.meta?.filters && setFilters(loanDetails?.meta?.filters);
      setLoanTotalPage(loanDetails?.meta?.totalCount ?? 1);
    }
  };

  useEffect(() => {
    fetchLoans();
  }, [approvedParams, approvedParams.searchText]);

  const handleSelectedLoans = (loans: number[]) => setSelectedLoans(loans);
  const handleSelectedRows = (loans: Loan[]) => setSelectedRows(loans);

  const changePreferredChannelCall = async () => {
    const filteredLoanIds = selectedRows
        .filter((row) => row.currentStatusTag !== BCStatusTags.LOAN_AGREEMENT_UPLOADED)
        .map((loan) => loan.id)

        const validLoanIds = filteredLoanIds.filter((id) => id !== undefined) as number[];
        if(validLoanIds.length > 0) {
            const result = await changePreferredChannel({ loanIds: validLoanIds });
            setPreferredChannelModal(false);
            handleSelectedLoans([]);
            fetchLoans();
        }
  }

  const {
    visible: approvedSettingsVisible,
    toggleVisibility: toggleApprovedSettingsVisible,
  } = useDrawer({});

  const {
    visible: approvedFiltersVisible,
    toggleVisibility: toggleApprovedFiltersVisible,
  } = useDrawer({});

  const {
    visible: isDisburseDrawerVisible,
    toggleVisibility: toggleDisburseDrawer,
  } = useDrawer({});
  const {
    visible: isChangeStatusVisible,
    toggleVisibility: toggleChangeStatusVisible,
  } = useDrawer({});

  const handleAmount = (loan: Loan[]) => {
    setAddedAmount(
      loan.reduce(
        (sum, { requestedLoanAmt }) => sum + Number(requestedLoanAmt),
        0
      )
    );
  };

  const modFilterChange = (value: string) => {
    updateSelectedValue(value, setSelectedValue, updateParams);
  };

  const checkUgroLoans = () => {
    const ugroLoans = selectedRows.some((loan) => loan.organizationName === organisationNames.UGRO)
    if (ugroLoans) {
      Notification({
       message: `UGRO Loans are not applicable for disbursement`,
       description: "",
       type: NotificationTypes.ERROR
     })
     return true
   }
   return false
  }
  
  const rowSelection = {
    selectedRowKeys: selectedLoans,
    onChange: (selectedRowKeys: React.Key[], loans: Loan[]) => {
      const paymentIntentIds = loans.map((loans) => loans?.id ?? 0);
      handleSelectedLoans(paymentIntentIds);
      handleSelectedRows(loans)
      handleAmount(loans);
      setExportButtonVisible(true)

      if(selectedRowKeys.length === 0) {
        setExportButtonVisible(false)
        setLoanDetails(false)
        setDisbursedStep(0)
      }

    },
  };

  const handleChangeModal = (change: string) => {
    const notApplicableLoansPresent = selectedRows.some((row) => row.currentStatusTag === BCStatusTags.LOAN_AGREEMENT_UPLOADED);
    if (notApplicableLoansPresent) {
      Notification({
        message: `These Loans are not applicable for the changing ${change}`,
        description: "",
        type: NotificationTypes.ERROR
      })
    } else {
      if (change === 'Status') {
        toggleChangeStatusVisible(true);
      } else if (change === 'Preferred Channel') {
        setPreferredChannelModal(true);
      }
    }
  }

  const handleUpdateParams = () => updateParams(getParams());

  const handleGenerateReport = async () => {
    const report = await generateLoanReport({
      loanIds: selectedLoans,
      templateName: "export_template",
    });
    report && setIsLoanGenerated(true);
  };

  const handleRowChange = (record: Loan) => ({
    onClick: () =>
      record?.id &&
      navigate(
        generatePath(AppRoutes.DISBURSEMENT_DETAILS, {
          loanId: String(record?.id),
        })
      ),
  });

  const handleChange: TableProps<LoanModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Loan>
    );
    updateParams({ ...approvedParams, page: current, sortBy, sortDirection });
  };

  const handleDisbursementStatus = async () => {
    const params = {
      loanIds: selectedLoans,
      status: LoanStatus.DISBURSEMENT_IN_PROGRESS,
    };

    const result = await initiateDisburseStatus(params);
    if (result?.loans) {
      setSelectedLoans([]);
      setDisbursedStep(0);
      setLoanDetails(false)
    }
    fetchLoans()
  };

  const changeStatusClose = () => {
    toggleChangeStatusVisible(false);
    handleSelectedLoans([]);
    fetchLoans()
  }

  const handleDisburseButton = () => {
    // if (!checkUgroLoans()) { 
      if (selectedLoans.length) {
        setDisbursedStep(1);
        setLoanDetails(true);
      } else {
        toggleDisburseDrawer(true);
      }
    // }
  }

  const toggleSelectAll = (loanValues: Loan[]) => {
    setSelectedLoans((keys) =>
      keys.length === loanValues.length
        ? []
        : loanValues.map((r) => Number(r.id))
    );
    setSelectedLoans(loanValues?.map((loan) => loan?.id).filter((id): id is number => id !== undefined));
    handleAmount(loanValues);
    setExportButtonVisible(true)
  };

  const handleDateChange = ({ approvedToDate, approvedFromDate }: DateRange) => {
    setDateRange({ approvedToDate: approvedToDate, approvedFromDate: approvedFromDate });
    updateParams({ ...approvedParams, approvedFromDate, approvedToDate });
  };
  
  return (
    <div className="approved-loans">
      <div className={`approved-loans-picker ${(loanDetails && disbursedStep !==2 && disbursedStep!==0) && 'approved-loans-picker-with-details'}`}>
      <Row>
        <Col span={9}>
        <Checkbox
              checked={selectedValue === modLoansTypes.ALL}
              onChange={() => modFilterChange(modLoansTypes.ALL)}
            >
              All Loans
            </Checkbox>
            <Checkbox
              checked={approvedParams?.securityType === modLoansTypes.SECURED}
              onChange={() => modFilterChange(modLoansTypes.SECURED)}
            >
              Secured Loans
            </Checkbox>
            <Checkbox
              checked={approvedParams?.securityType === modLoansTypes.UNSECURED}
              onChange={() => modFilterChange(modLoansTypes.UNSECURED)}
            >
              Unsecured Loans
            </Checkbox>
        </Col>
          <Col span={6}>
            <CustomRangePicker
              approvedDate
              className="mr-5 custom-range-picker"
              onChange={handleDateChange}
              values={{
                toDate: approvedParams?.approvedToDate,
                fromDate: approvedParams?.approvedFromDate,
              }}
            />
          </Col>
          <Col span={6}>
          <Button disabled={!selectedLoans.length} onClick={() => handleChangeModal('Preferred Channel')} className="preferred-channel-btn">Change Preferred Channel</Button>
          </Col>
        </Row>
      </div>
      {!loading && filters && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <Table
        className='approved-loans-table'
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        {...(userAccess?.writeAccess && {
          rowSelection: {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            ...rowSelection,
          },
        })}
        dataSource={loans}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: approvedParams?.page,
          total: loanTotalPage,
          hideOnSinglePage: true,
        }}
      />
        <div className={`approved-extra-content ${(loanDetails && disbursedStep!==2 && disbursedStep!==0) && 'approved-top'}`}>
        <div className={`selected-loan-details ${disbursedStep===1 && 'extra-margin'}`}>
          <Row gutter={[50, 0]}>
            {disbursedStep === 1 && exportButtonVisible && (
              <>
                <Col span={5}>
                  <span className="loan__text">
                    Selected Loans ({selectedLoans.length})
                  </span>
                </Col>

                <Col span={5} className="">
                  <span className="text-primary">
                    Added Amount :{" "}
                    <span className="text-bold mt-5"> {addedAmount}</span>{" "}
                  </span>
                </Col>

                {
                  <Col span={6}>
                    <span className="text-primary">
                      Remaining Amount :{" "}
                      <span className="loan__text">
                        {disburseAmount ? disburseAmount - addedAmount : "NA"}
                      </span>{" "}
                    </span>
                  </Col>
                }
              </>
            )}
            {disbursedStep === 0 && userAccess?.writeAccess && (
              <div className="d-flex">
                {/* <CustomRangePicker
                    className="mr-5 custom-range-picker"
                    onChange={handleDateChange}
                  /> */}
                {/* to be implemented after design update */}

                <Button
                  disabled={!selectedLoans.length}
                  className="loan-status-btn mr-2"
                  onClick={() => handleChangeModal('Status')}
                >
                  Change Status
                </Button>

                <Button
                  className="loan-disburse-btn ml-2 mr-2"
                  onClick={handleDisburseButton}
                >
                  {selectedLoans.length
                    ? "DISBURSE LOAN"
                    : "SELECT LOAN TO DISBURSE"}
                </Button>
              </div>
            )}
            {disbursedStep === 1 && exportButtonVisible &&(
              <>
                <Col span={5}>
                  <Button
                    disabled={!selectedLoans.length}
                    className="loan-export-btn "
                    onClick={handleGenerateReport}
                  >
                    <img alt="export-data-img" className="download-icon mr-5" src={DownloadIcon} />
                    EXPORT DATA
                  </Button>
                </Col>
                <Col span={2} className="text-right ml-5 next-button">
                  <Button
                    disabled={!isLoanGenerated}
                    className="loan-disburse-btn ml-5 "
                    onClick={() => setDisbursedStep(2)}
                  >
                    NEXT
                  </Button>
                </Col>
              </>
            )}

            {disbursedStep === 2 && (
              <>
                <Col className="text-right d-flex export-change-status">Change Status:</Col>
                <Col className="text-right mr-5">
                  <Button
                    disabled={!selectedLoans.length}
                    className="loan-disburse-btn"
                    onClick={handleDisbursementStatus}
                  >
                    DISBURSEMENT IN PROGRESS
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </div>
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: approvedParams.searchText,
          }}
          filterClick={toggleApprovedFiltersVisible}
          settingsClick={toggleApprovedSettingsVisible}
        />
      </div>
      <Drawer
        placement="right"
        visible={approvedSettingsVisible}
        destroyOnClose
        onClose={() => toggleApprovedSettingsVisible(false)}
        title="Column Options"
        width={500}
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleApprovedSettingsVisible(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
      <Modal
        visible={isChangeStatusVisible}
        onCancel={() => toggleChangeStatusVisible(false)}
        cancelButtonProps={{ className: "modal-footer__cancel" }}
        destroyOnClose
        centered
        footer={false}
      >
        <ChangeStatus
          selectedLoans={selectedLoans}
          activeTab="1"
          onClose={changeStatusClose}
        />
      </Modal>
      <Drawer
        placement="right"
        visible={isDisburseDrawerVisible}
        destroyOnClose
        onClose={() => toggleDisburseDrawer(false)}
        title="Disburse Amount"
        width={"35vw"}
      >
        <DisburseAmountForm
          onSuccess={(loansValue: Loan[], formValues: DisbursementModel) => {
            setLoans(loansValue);
            setDisbursedStep(0);
            setDisburseAmount(formValues?.disburseAmount ?? 0);
            formValues.process === Process.AUTOMATIC && toggleSelectAll(loansValue);
          }}
          loanDisbursedStep={(step: number) => setDisbursedStep(step)}
          toggleDisburseDrawer={toggleDisburseDrawer}
          setSelectedLoans={setSelectedLoans}
        />
      </Drawer>
      <Drawer
        placement="right"
        visible={approvedFiltersVisible}
        destroyOnClose
        onClose={() => toggleApprovedFiltersVisible(false)}
        title="Filters"
        width={"70vw"}
      >
        <LoanFilters
          onFilter={updateParams}
          onClose={() => toggleApprovedFiltersVisible(false)}
        />
      </Drawer>
      <CustomModal
      title="Change Preferred Channel"
      visible={preferredChannelModal}
      onCancel={()=>setPreferredChannelModal(false)}
      onOk={changePreferredChannelCall}
      >
        <Select
        allowClear
        options={changePreferredChannelOptions}
        placeholder="Select a  Preferred Channel"
        >
        </Select>
      </CustomModal>
      <MODUploadForm refreshData={fetchLoans} loan={modLoan} visible={uploadModModal} onClose={()=> setUploadModal(false)} securityDocument={true} />
    </div>
  );
};

export default ApprovedLoans;

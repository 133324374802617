import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../../../../shared/components/InputField";
import { InputType } from "../../../../../enums/input.type";
import { Button } from "antd";
import "../loanRepaymentSchedule.scss";
import { Loan } from "../../../../../models/loan.model";
import { PreCloseLoan as PreCloseLoanModel } from "../../../../../models/preCloseLoan.model";
import { LoanService } from "../../../../../services/Loan/loan.service";
import { preCloseValidation } from "./preCloseValidation";
import DatePickerField from "../../../../../shared/components/DatePickerField";
import { DatePickerTypes } from "../../../../../enums/datePicker.type";
import { allowPastSevenDays } from "../../../../../shared/utils/dateUtils";
import { precloseConstants } from "./constants";
import { Moment } from "moment";
import { dateTimeFormatter } from "../../../../../shared/utils/formatter";
import { DateFormat } from "../../../../../enums/dateFormat.type";
interface PreCloseProps {
  loan: Loan;
  onClose: () => void;
}

function PreCloseLoan(props: PreCloseProps) {
  const { updatePreCloseLoan, getPrecloseAmount } = LoanService();
  const { loan, onClose } = props;
  const [ precloseAmount, setPrecloseAmount ] = useState(false)
  const [formValues, setFormValues] = useState<PreCloseLoanModel>(
    new PreCloseLoanModel()
  );

  const onSubmit = async (values: PreCloseLoanModel) => {
    const preLoan = await updatePreCloseLoan({
      id: loan?.id,
      amount: values?.amountCollected,
      precloseDate: values?.precloseDate
    });
    if (preLoan) onClose();
  };
  const initializeFormValue = () => {
    setFormValues({
      id: loan?.id,
      customerName: loan?.customer?.getName(),
      outstandingAmount: Number(loan?.preClosedAmount),
      numberOfpendingDues: Number(loan?.numberOfpendingDues),
    });
  };

  useEffect(() => {
    initializeFormValue();
  }, []);

  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={preCloseValidation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, isValid, dirty, setFieldValue }) => {

          const handleDateChange = async (date: Moment) => {
            if (date === null) {
              setPrecloseAmount(false)
              return
            }
            const formattedDate = dateTimeFormatter(date, DateFormat.DATE)
            setFieldValue(precloseConstants.FORM_FIELDS.PRECLOSE_DATE.FIELD, formattedDate)
            const data = await getPrecloseAmount({
              id: loan?.id,
              precloseDate: formattedDate
            })
            setPrecloseAmount(true)
            setFieldValue(precloseConstants.FORM_FIELDS.PRECLOSE_AMOUNT.FIELD, data?.precloseAmount)
          }
          return (
          <>
            <div className="pre-close-loan">
              <div className="pre-close-loan__content">
                <Form className="ant-form-vertical">
                  <InputField
                    type={InputType.NUMBER}
                    name="id"
                    placeholder=""
                    label="Loan ID"
                    disabled
                  />
                  <InputField
                    type={InputType.TEXT}
                    name={`customerName`}
                    placeholder=""
                    label="Customer Name"
                    disabled
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="outstandingAmount"
                    placeholder=""
                    label="Outstanding Amount"
                    disabled
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="numberOfpendingDues"
                    placeholder=""
                    label="No of Dues Pending"
                    disabled
                  />
                  <DatePickerField
                    onChange={handleDateChange} 
                    name={precloseConstants.FORM_FIELDS.PRECLOSE_DATE.FIELD}
                    type={DatePickerTypes.DATE} 
                    disabledDate={allowPastSevenDays} 
                    title={precloseConstants.FORM_FIELDS.PRECLOSE_DATE.LABEL}
                    placeholder={precloseConstants.FORM_FIELDS.PRECLOSE_DATE.PLACEHOLDER} 
                  />
                  { precloseAmount && (
                  <InputField
                    disabled
                    type={InputType.TEXT}
                    name={precloseConstants.FORM_FIELDS.PRECLOSE_AMOUNT.FIELD}
                    placeholder={precloseConstants.FORM_FIELDS.PRECLOSE_AMOUNT.PLACEHOLDER}
                    label={precloseConstants.FORM_FIELDS.PRECLOSE_AMOUNT.LABEL}
                  />
                  )}
                  <InputField
                    type={InputType.TEXT}
                    name="amountCollected"
                    placeholder="Enter Amount Collected"
                    label="Amount Collected"
                  />
                  <div className="pre-close-loan__content__footer drawer-footer">
                    <Button
                      className="drawer-footer__cancel"
                      htmlType="reset"
                      onClick={onClose}
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmitting}
                      className="drawer-footer__submit mr-5"
                      htmlType="submit"
                      disabled={!isValid || !dirty}
                    >
                      SEND RECEIPT
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )
        }}
      </Formik>
    </div>
  );
}
export default PreCloseLoan;

import { UserStatus, UserStatusLabel } from "../../../../../../enums/userStatus";

export const getStatusValue = (status: string) => {
    switch (status) {
        case UserStatus.ACTIVE:
            return UserStatusLabel.ACTIVE;
        case UserStatus.FREEZED:
            return UserStatusLabel.FROZEN;
        case UserStatus.INACTIVE:
            return UserStatusLabel.IN_ACTIVE;

        default:
            return "";
    }
};

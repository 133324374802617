import { Col, Collapse, Row, Space } from "antd";
import React, { useState } from "react";
import "../../IndividualKeyFactor";
import paymentStatusImg from "../../../../../Assets/images/On-Time Payments.svg";
import "../individualKeyFactor.scss";
import {
  ListMappingKeys,
} from "../../../../../shared/types/optionArray.type";
import { CrifReport } from "../../../../../models/creditReport.model";
import { calculateAveragePercentage, getCrifStatus } from '../../../../../shared/utils/creditReportUtil';
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { AccountsDetails } from "../../../../../models/accountsDetails.model";
import PaymentHistory from "../../../Total Accounts/PaymentTable";
import { getStatusClass } from "../../../utils";
import { toTitleCase } from '../../../../../shared/utils/titleCaseConverter';
import { CreditReportKeyFactorLabels } from "../constants";

interface PaymentStatusFactorProps {
  data?: CrifReport;
}

const PaymentStatusFactor = (props: PaymentStatusFactorProps) => {
  const { data } = props;

  const { Panel } = Collapse;

  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([]);

  const handlePanelChange = (keys: string | string[]) => {
    setActiveCollapseKeys(Array.isArray(keys) ? keys : [keys]);
  };

  const keyFactorData: ListMappingKeys[] = [
    {
      label: CreditReportKeyFactorLabels.TOTAL_PAYMENTS,
      value: data?.paymentStatus?.totalPayments,
      offset: 3,
      key: "1",
      className: "text-blue",
    },
    {
      label: CreditReportKeyFactorLabels.ON_TIME_PAYMENTS,
      value: data?.paymentStatus?.onTimePayments,
      className: "text__green",
      offset: 4,
      key: "2",
    },
    {
      label: CreditReportKeyFactorLabels.LATE_PAYMENTS,
      value: data?.paymentStatus?.latePayments,
      className: "text__red",
      offset: 4,
      key: "3",
    },
    {
      label: CreditReportKeyFactorLabels.NO_PAYMENT_INFORMATION,
      value: data?.paymentStatus?.noPaymentInformation,
      offset: 4,
      key: "4",
    },
  ];

  const renderPanelContent = (account: AccountsDetails) => {
    const panelDetails: ListMappingKeys[] = [
      {
        label: CreditReportKeyFactorLabels.ACCOUNT_NUMBER,
        value: fillEmptyData(account.accountNumber),
        key: "1",
      },
      {
        label: CreditReportKeyFactorLabels.ON_TIME_PAYMENTS,
        value: fillEmptyData(`${account.onTimePayments}/${account.totalPayments}`),
        key: "2",
      },
      {
        label: CreditReportKeyFactorLabels.LAST_UPDATED,
        value: fillEmptyData(account.reportedOn),
        key: "3",
      },
      {
        label: CreditReportKeyFactorLabels.STATUS,
        value: fillEmptyData(toTitleCase(account.accountStatus)),
        key: "4",
        className: getStatusClass(account.accountStatus)
      },
    ];
    return (
      <Row gutter={[16, 16]} className="panel-component-first">
        <Col>
          <img src={paymentStatusImg} alt="Total Accounts Logo" />
        </Col>
        <Col className="flex-column" span={5}>
          <span>{account.lender}</span>
          <span className="block__label text-capitalize">{account.accountType}</span>
        </Col>
        {panelDetails.map((detail) => (
          <Col
            key={detail?.key}
            span={detail.label === CreditReportKeyFactorLabels.LAST_UPDATED ? 3 : detail.label === CreditReportKeyFactorLabels.STATUS ? 2 : 5}
            className="block__value"
          >
            <span className={detail.className}>{detail.value}</span>
            <div className={`block__label text-capitalize`}>{detail.label}</div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="payment-status">
      <Row className="card crif-card" align={"middle"}>
        <Col span={6}>
        <Space direction="horizontal" size={12}>
          <Col span={1}>
            <img src={paymentStatusImg} alt="Payment Status" />
          </Col>
          <Col span={24}>{CreditReportKeyFactorLabels.PAYMENT_STATUS}</Col>
        </Space>
        </Col>
        {keyFactorData.map((data) => (
          <Col span={data.offset} className="flex-column" key={data.key}>
            <span className="font-weight__600 font-size__18">
              {data.value || 0}
            </span>
            <span className={`${data.className}`}>{data.label}</span>
          </Col>
        ))}
        <Col className="flex-column">
          <div className="gap-3 d-flex">
            <span className="font-weight__600 font-size__18">
            {`${calculateAveragePercentage(data?.paymentStatus?.onTimePayments, data?.paymentStatus?.totalPayments)}%`}
            </span>
            <span>{CreditReportKeyFactorLabels.ON_TIME}</span>
          </div>
          <span className={`text__green text-left`}>
            {fillEmptyData(getCrifStatus(calculateAveragePercentage(data?.paymentStatus?.onTimePayments, data?.paymentStatus?.totalPayments)))}
          </span>
        </Col>
      </Row>
      <Collapse
        className="mt-5 mb-5"
        activeKey={activeCollapseKeys}
        expandIconPosition="end"
        onChange={handlePanelChange}
        bordered={false}
        ghost={true}
      >
        {data?.creditAccounts?.accountsDetails?.map((account, index) => {
          return (
            <Panel
              className="card mt-5 mb-5"
              key={index + 1}
              header={renderPanelContent(account)}
            >
              <PaymentHistory account={account} />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default PaymentStatusFactor;

export const CreditReportKeyFactorLabels = {
    LAST_UPDATED: "Last updated",
    STATUS: "Status",
    ACCOUNT_NUMBER: "Account Number",
    YEARS: "Years",
    YEAR: "Year",
    NA: "NA",
    ACCOUNT_SUMMARY: "Account Summary",
    CREDIT_AGE: "Credit Age",
    MONTHS: "Months",
    YOUR_ACTIVE_LOANS: "Your Active Loans",
    YOUR_CLOSED_LOANS: "Your Closed Loans",
    WEEKLY: "Weekly",
    MONTHLY_EMI: "Monthly EMI",
    MONTHY_EMI_LOANS: "Monthly EMI Loans",
    PER_MONTH: "/month",
    TOTAL_OD_ACCOUNTS: "Total OD Accounts",
    TOTAL_OD_AMOUNT: "Total OD Amount",
    DAYS_PAST_DUE: "Days Past Due",
    OD_AMOUNT: "OD Amount",
    OD_ACCOUNTS_LABEL: "OD (Overdue Accounts)",
    PAYMENT_STATUS: "Payment Status",
    TOTAL_PAYMENTS: "Total Payments",
    ON_TIME_PAYMENTS: "On-Time Payments",
    LATE_PAYMENTS: "Late Payments",
    NO_PAYMENT_INFORMATION: "No Payment Information",
    ON_TIME: "On Time",
    TOTAL_SETTLEMENTS_ACCOUNTS: "Total Settlements Accounts",
    TOTAL_SETTLEMENTS_AMOUNT: "Total Settlements Amount",
    TOTAL_SETTLEMENTS: "Total Settlements",
    TOTAL_WRITE_OFF: "Total Write Off",
    TOTAL_WRITE_OFF_ACCOUNTS: "Total Write Off Accounts",
    TOTAL_WRITE_OFF_AMOUNT: "Total Write Off Amount",
    CREDIT_ENQUIRIES_REPORTED: "Enquiries reported for past 24 months",
    ACCOUNTS_ACTIVE: " active / ",
    INFORMATION_CONTRIBUTIONS: "These information variations as contributed by various financial institutions.",
    PAYMENT_HISTORY: "Payment history of this account (last 4 years)",
    OD_PAYMENTS: "OD Payments",
    NO_PAYMENT_INFO: "No Payment Info",
    DOT: "●",
    ACCOUNT_HOLDER_TYPE: "Account Holder Type",
    LOAN_AMOUNT: "Loan Amount",
    LOAN_DISBURSED: "Loan Disbursed",
    WRITE_OFF_AMOUNT: "Write-Off Amount",
    AMOUNT_REMAINING: "Amount Remaining",
    AMOUNT_PAID: "Amount Paid",
    INSTALMENT_AMOUNT: "Instalment Amount",
    INSTALMENT_FREQUENCY: "Instalment Frequency",
    TOTAL_ACCOUNTS: "Total Accounts",
    CLOSED_ACCOUNTS: "ClosedAccounts",
    ACTIVE_ACCOUNTS: "Active Accounts",
    OVERALL_SCORE: "Overall Score",
    EXPAND_ALL : "+ Expand All",
    TOTAL_ACCOUNTS_LOGO: "Total Accounts Logo",
    COLLAPSE_ALL: "- Collapse All"

}
import { Button, Col, Image, Row } from "antd";
import React, { ReactElement, useState } from "react";

import { Customer } from "../../../../../models/customer.model";
import { percentageFormatter } from "../../../../../shared/utils/formatter";
import { getFullName } from "../../../../../shared/utils/getFullName";
import verifiedImage from "../../../../../Assets/images/verified.png";
import "../BankDetails/bankDetails.scss";
import { Loan } from "../../../../../models/loan.model";
import { VerificationStatus } from "../../../../../enums/verificationStatus";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import CustomModal from "../../../../../shared/components/CustomModal";
import { LoanService } from "../../../../../services/Loan/loan.service";
import * as AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import { useLocation } from "react-router-dom";
import { hasAccessOf } from "../../../../../shared/utils/hasAccess";
import { RoleName } from "../../../../../enums/roleName.enum";
import CustomImage from "../../../../../shared/components/CustomImage";
import { CustomerDetail } from "../../../../../models/customerDetail.model";

interface CustomerBankDetailsProps {
  customer: CustomerDetail;
  loan?: Loan;
  onSuccess?: () => void;
}

const CustomerBankDetails = (props: CustomerBankDetailsProps) => {
  const location = useLocation();
  const { customer, loan, onSuccess } = props;
  const path = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
  const HOAccess =
    hasAccessOf(RoleName.HEAD_OFFICER) && path === AppRoutes.CT_VERIFICATION;

  const isCTVerification = location?.pathname.includes(
    AppRoutes.CT_VERIFICATION
  );

  const [modalVisible, setModalVisible] = useState(false);

  const { updateNameScoreMatch, loading } = LoanService();

  const handleModalVisibility = () => setModalVisible(!modalVisible);

  const handleConfirmNameMatching = async () => {
    const data = await updateNameScoreMatch(String(loan?.id));
    if (data) {
      onSuccess && onSuccess();
      handleModalVisibility();
    }
  };

  return (
    <Row gutter={[16, 16]} className="block bank-details">
      <Col span={24} className="block__heading">
        Bank Account Details
      </Col>

      <Col span={5} className="block__label">
        Bank Name
        <div className="block__value">
          {fillEmptyData(customer?.bankDetails[0]?.loanBankDetail?.bankName)}
        </div>
      </Col>

      <Col span={5} className="block__label">
        Branch Name
        <div className="block__value">
          {fillEmptyData(customer?.bankDetails[0]?.loanBankDetail?.branchName)}
        </div>
      </Col>
      <Col span={10} className="block__label">
        Name in the Application
        <div className="block__value">
          {getFullName(customer)}
        </div>
      </Col>

      <Col span={4} className="block__label">
        Name in the Bank
        <div className="block__value"> {fillEmptyData(
            customer?.bankDetails[0]?.loanBankDetail?.registeredName
          )}</div>
      </Col>

      <Col span={5} className="block__label">
        Account No.
        <div className="block__value">
          {fillEmptyData(
            customer?.bankDetails[0]?.loanBankDetail?.accountNumber
          )}
          {loan?.verification?.bankAccount === VerificationStatus.vertified && (
            <Image
              className="w-30 ml-2"
              src={verifiedImage}
              alt="verified"
              preview={false}
            />
          )}
        </div>
      </Col>

      <Col span={5} className="block__label">
        IFSC
        <div className="block__value">
          {fillEmptyData(customer?.bankDetails[0]?.loanBankDetail?.ifscCode)}
        </div>
      </Col>

      <Col span={10} className="block__label">
        Name Match & Score
        <div className="block__value ">
          <span className="mr-2">
            {fillEmptyData(customer?.bankDetails[0]?.loanBankDetail?.nameMatch)}
            ,
            <span className="ml-3">
              {percentageFormatter({
                value: Number(customer?.bankDetails[0]?.loanBankDetail?.score),
              })}
            </span>
          </span>
          {loan?.isBankScoreApprovedByHo && (
            <span>
              <Image
                src={verifiedImage}
                width={19}
                height={19}
                preview={false}
              />
            </span>
          )}
          {isCTVerification && (
            <Button
              className="ml-3"
              onClick={handleModalVisibility}
              disabled={loan?.isBankScoreApprovedByHo}
            >
              Confirm Verify
            </Button>
          )}
        </div>
      </Col>

      {loan?.isBankScoreApprovedByHo && (
        <Col span={4} className="block__label">
          Verified person name
          <div className="block__value">
            {fillEmptyData(loan?.bankScoreVerifiedBy)}
          </div>
        </Col>
      )}

      <CustomModal
        onCancel={handleModalVisibility}
        title="Confirm Name Matching"
        visible={modalVisible}
        onOk={handleConfirmNameMatching}
        onClose={handleModalVisibility}
        loading={loading}
      >
        Are you sure you want to confirm the Name Match for this customer?
      </CustomModal>
    </Row>
  );
};

export default CustomerBankDetails;

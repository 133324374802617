import { Col, Row, Space } from "antd";
import React from "react";
import creditAge from "../../../../../Assets/images/Credit Age.svg";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { CrifReport } from "../../../../../models/creditReport.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import '../individualKeyFactor.scss'
import { formatLoanDuration, formatLoanLabel, getStatusClass } from "../../../utils";
import { toTitleCase } from '../../../../../shared/utils/titleCaseConverter';
import { CreditReportKeyFactorLabels } from '../constants';

interface CreditAgeProps {
  data?: CrifReport;
}

const CreditAge = (props: CreditAgeProps) => {
  const { data } = props;

    const creditAgeData = data?.creditAccounts?.accountsDetails?.map((account) => [
    {
      label: CreditReportKeyFactorLabels.ACCOUNT_NUMBER,
      value: fillEmptyData(account.accountNumber),
      key: "1",
      offset: 5
    },
    {
      label: formatLoanLabel(account.loanCreditYears, account.loanCreditMonths),
      value: formatLoanDuration(account.loanCreditYears, account.loanCreditMonths),
        key: "2",
        className: "text__label-green font-weight__600",
        offset: 5
      },
    {
      label: CreditReportKeyFactorLabels.LAST_UPDATED,
      value : fillEmptyData(account.lastUpdated),
      key: "3",
      offset: 4,
    },
    {
      label: CreditReportKeyFactorLabels.STATUS,
      value: fillEmptyData(toTitleCase(account.accountStatus)),
      key: "4",
      className: getStatusClass(account.accountStatus)
    }
  ]
  )

  const titleCardData: ListMappingKeys[] = [
    {
      label: CreditReportKeyFactorLabels.YOUR_ACTIVE_LOANS,
      value: data?.creditAccounts?.totalActiveAccounts,
      offset: 5,
      key: "1"
    },
    {
      label: CreditReportKeyFactorLabels.YOUR_CLOSED_LOANS,
      value: data?.creditAccounts?.totalClosedAccounts,
      offset: 5,
      key: "2"
    }
  ]

  return (
    <div>
      <Row className="card crif-card" align="middle">
        <Col span={11}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={creditAge} alt="" />
          </Col>
          <Col>{CreditReportKeyFactorLabels.CREDIT_AGE}</Col>
        </Space>
        </Col>
        {titleCardData?.map((data) => (
          <Col span={data.offset} key={data.key} className="flex-column">
            <span className="font-weight__600 font-size__18">
              {data?.value || 0}
            </span>
            <span>{data?.label}</span>
          </Col>
        ))}
        <Col className="flex-column">
          <div>
            <Space>
              <span className="font-weight__600 font-size__18">{data?.creditAccounts?.creditYears || 0}</span>
              <span>{CreditReportKeyFactorLabels.YEARS}</span>
              <span className="font-weight__600 font-size__18">{data?.creditAccounts?.creditMonths || 0}</span>
              <span>{CreditReportKeyFactorLabels.MONTHS}</span>
            </Space>
          </div>
          {/* commenting till confirmation from BE */}
          {/* <span className="text__green text-right">{fillEmptyData(data?.creditAccounts?.creditStatus)}</span> */}
        </Col>
        
      </Row>
     {creditAgeData?.map((account, index) => (
        <Row className={`card crif-card mt-5 credit-age-card`} align={"middle"} key={index}>
          <Col span={7}>
          <Space direction="horizontal">
            <Col>
              <img src={creditAge} alt="" />
            </Col>
            <Col className="flex-column key-factor-col">
              <span className="text__value">{fillEmptyData(data?.creditAccounts?.accountsDetails?.[index]?.lender)}</span>
              <span className="text__label">{fillEmptyData(data?.creditAccounts?.accountsDetails?.[index]?.accountType)}</span>
            </Col>
          </Space>
          </Col>
          {account?.map((data) => (
            <Col span={data?.offset} key={data.key} className={`flex-column key-factor-col ${data?.className} col-${data.key}`}>
              <span className={`text__value ${data?.className}`}>{data?.value}</span>
              <span className="text__label">{data?.label}</span>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default CreditAge;

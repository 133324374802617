import React from 'react'
import tick from '../../../../Assets/images/tick.png'
import yellowSquare from '../../../../Assets/images/yellow-square.png'
import redSquare from '../../../../Assets/images/red-square.png'
import './tableHeader.scss';
import { CreditReportKeyFactorLabels } from '../../KeyFactors/IndividualKeyFactor/constants';

const TableHeader = () => {
  return (
    <div className='table-header mt-5'>
      <div className='header'>
        {CreditReportKeyFactorLabels.PAYMENT_HISTORY}
      </div>
      <div className='payment-types-container'>
        <div className='payment-type'>
          <span><img src={tick} width={18} height={18} alt='' /></span>
          <span>{CreditReportKeyFactorLabels.ON_TIME_PAYMENTS}</span>
        </div>
        <div className='payment-type'>
          <span><img src={yellowSquare} width={18} height={18} alt='' /></span>
          <span>{CreditReportKeyFactorLabels.LATE_PAYMENTS}</span>
        </div>
        <div className='payment-type'>
          <span><img src={redSquare} width={18} height={18} alt='' /></span>
          <span>{CreditReportKeyFactorLabels.OD_PAYMENTS}</span>
        </div>
        <div className='payment-type'>
          <span className="active-status--no-info">{CreditReportKeyFactorLabels.DOT}</span>
          <span>{CreditReportKeyFactorLabels.NO_PAYMENT_INFO}</span>
        </div>
      </div>
    </div>
  )
}

export default TableHeader
import { Col, Row, Space } from "antd";
import React from "react";
import totalSettlements from "../../../../../Assets/images/Total Settlements.png";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { SettledAccounts } from "../../../../../models/settledAccounts.model";
import { CreditReportKeyFactorLabels } from "../constants";

interface TotalSettlementsProps {
  data?: SettledAccounts
}

const TotalSettlements = (props: TotalSettlementsProps) => {

  const { data } = props;

  const titleCardData: ListMappingKeys[] = [
    {
      label: CreditReportKeyFactorLabels.TOTAL_SETTLEMENTS_ACCOUNTS,
      value: data?.totalSettlementsAccount,
      offset: 5,
      key: "1"
    },
    {
      label: CreditReportKeyFactorLabels.TOTAL_SETTLEMENTS_AMOUNT,
      value: currencyFormatter({amount: data?.totalSettlementsAmount, hasCurrencySign: true, shouldReturnNA: false}),
      offset: 5,
      key: "2"
    }
  ]

  return (
    <div>
      <Row className="card crif-card" align={"middle"}>
        <Col span={11}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={totalSettlements} alt="" />
          </Col>
          <Col>{CreditReportKeyFactorLabels.TOTAL_SETTLEMENTS}</Col>
        </Space>
        </Col>
        {titleCardData?.map((data) => {
          return (
            <Col span={data?.offset} key={data.key} className="flex-column">
              <span className="font-weight__600 font-size__18">{data.value || 0}</span>
              <span className="text-secondary">{data?.label}</span>
            </Col>
          )
        })}
        <Col className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({amount: data?.totalSettlementsAmount, hasCurrencySign: true, shouldReturnNA: false})}</span>
          </div>
          {/* commenting till confirmation from BE */}
          {/* <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span> */}
        </Col>
      </Row>
    </div>
  );
};

export default TotalSettlements;

import * as Yup from "yup";
import { branchDetailFormConstants } from "./constants";

export const branchDetailValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, "Minimum two characters")
    .max(25, "Maximum 25 characters")
    .required()
    .label("Branch").nullable(),
  regionId: Yup.string().required().label("Region").nullable(),
  distance: Yup.number().required().label(branchDetailFormConstants.DISTANCE.LABEL).nullable(),
  code: Yup.string().trim().nullable().required().label("Branch ID").nullable(),
  email: Yup.string().email().required().label("Email ID").nullable(),
  mobileNumber: Yup.string()
    .matches(/^[6-9]\d{9}$/gi, "Phone number is not valid")
    .required()
    .label("Phone Number").nullable(),
  address: Yup.object().shape({
    addressLine1: Yup.string()
      .trim()
      .min(5, "Minimum 5 Characters Long")
      .max(45, "Maximum 45 Characters")
      .required()
      .label("Address Line 1").nullable(),
    addressLine2: Yup.string().trim().nullable().label("Address Line 2").nullable(),
    stateId: Yup.string().required().label("State").nullable(),
    landmark: Yup.string().trim().nullable().label("Landmark").nullable(),
    cityId: Yup.string().required().label("City").nullable(),
    pincode: Yup.string()
      .test("Pincode validation", "Pincode must be a number", (val) =>
        Boolean(Number(val))
      )
      .min(6, "Must be six digit")
      .max(6, "Must be Six digit")
      .required()
      .label("Pincode")
      .nullable(),
  }),
  organisationId: Yup.string().trim().required().label("Preferred Channel").nullable(),
});

import { CreditReportKeyFactorLabels } from '../KeyFactors/IndividualKeyFactor/constants';

export const getStatusClass = (status: string | undefined): string => {
  return status ? `status--${status.toLowerCase()}` : '';
};

export const formatLoanDuration = (loanCreditYears: number = 0, loanCreditMonths: number = 0) =>
  loanCreditYears === null && loanCreditMonths === null
    ? CreditReportKeyFactorLabels.NA
    : loanCreditYears === null
      ? `${(loanCreditMonths / 12).toFixed(1)}`
      : loanCreditMonths === null
        ? `${loanCreditYears}`
        : `${loanCreditYears}.${loanCreditMonths || 0}`;

export const formatLoanLabel = (loanCreditYears: number = 0, loanCreditMonths: number = 0) =>
  (loanCreditYears === null && loanCreditMonths === null)
    ? CreditReportKeyFactorLabels.YEARS
    : parseFloat(`${loanCreditYears || 0}.${loanCreditMonths || 0}`) > 1
      ? CreditReportKeyFactorLabels.YEARS
      : CreditReportKeyFactorLabels.YEAR;

export const prefixZero = (number: number = 0) => {
  return number < 10 ? `0${number}` : number.toString();
}
import { UserStatus } from "../../../../../../enums/userStatus";

const statusValues = {
  [UserStatus.ACTIVE]: { isActive: true, isFrozen: false },
  [UserStatus.INACTIVE]: { isActive: false, isFrozen: false },
  [UserStatus.FREEZED]: { isActive: false, isFrozen: true },
};

export const getStatusValues = (status: UserStatus) => {
  return statusValues[status] || { isActive: false, isFrozen: false };
};

import { UserStatus } from "../../../../../../enums/userStatus";
import { Meta } from "../../../../../../models/meta.modal";

export const filterStatusOptions = (
  options: Meta[],
  isActive?: boolean,
  isFrozen?: boolean
): Meta[] => {
  const statuses: UserStatus[] = 
    isActive 
      ? [UserStatus.FREEZED, UserStatus.INACTIVE] 
      : isFrozen 
      ? [UserStatus.ACTIVE, UserStatus.INACTIVE] 
      : [UserStatus.ACTIVE, UserStatus.FREEZED];

  return options.filter(option => 
    statuses.includes(option.value as UserStatus)
  );
};

import React from "react";
import { renderPaymentStatus } from "../../../shared/utils/renderPaymentStatus";
import { shortMonths } from "../../../shared/Constant/monthsOptions";
import { PaymentStatuses } from "../../../enums/paymentStatus.type";
import "./panelHeader.scss";
import { LoanPaymentHistory } from "../../../models/loanPaymentHistory.model";
import TableHeader from "./TableHeader";
import { CreditReportKeyFactorLabels } from "../KeyFactors/IndividualKeyFactor/constants";
import { AccountsDetails } from "../../../models/accountsDetails.model";

interface PaymentHistoryProps {
  account: AccountsDetails;
}

const PaymentHistory: React.FC<PaymentHistoryProps> = ({ account }) => {
  return (
    <div className="payment-history">
      {/* Displaying The Year Row */}
      <TableHeader />
      <div className="payment-row header">
        <span className="payment-year">{CreditReportKeyFactorLabels.YEAR}</span>
        <div className="year-flex">
          {shortMonths.map((month) => (
            <span key={month} className="month">
              {month.toUpperCase()}
            </span>
          ))}
        </div>
      </div>

      {Array.from(
        new Set(
          account?.loanPaymentHistory?.map((payment: LoanPaymentHistory) => payment.year))).map((year) => (
            <div className="payment-row">
              <span className="payment-year">{year}</span>
              <div className="year-flex">
                {shortMonths.map((month) => {
                  const paymentRecord = account?.loanPaymentHistory?.find(
                    (payment: LoanPaymentHistory) =>
                      payment.year === year && payment.month === month
                  );

                  return (
                    <span key={`${year}-${month}`} className="payment-status">
                      {paymentRecord
                        ? renderPaymentStatus(paymentRecord.paidStatus)
                        : renderPaymentStatus(PaymentStatuses.NO_INFO)}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
    </div>
  );
};

export default PaymentHistory;

import React, { useEffect, useState } from "react";
import "../loanClaims.scss";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanClaimsModel, LoanClaimsParams } from "../../../models/loanClaims.model";
import Table from "../../../shared/components/Table";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import { Drawer, TableColumnsType, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { TableConfig } from "../../../enums/tableConfig.type";
import { ColumnProps } from "antd/lib/table";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { DateFormat } from "../../../enums/dateFormat.type";
import { currencyFormatter, dateTimeFormatter } from "../../../shared/utils/formatter";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { ClaimsService } from "../../../services/Claims/claims.service";
import { LoanClaimReceivedStatus, LoansClaimsStatus } from "../../../enums/loanClaimsStatuses.type";
import { toTitleCase } from "../../../shared/utils/titleCaseConverter";

const sortBy = {
  loanCode: "loan_code",
  role: "role",
  nameOfExpired: "name_of_expired",
  deathDate: "death_date",
};

const ClaimedClaims = () => {
  const {
    params: claimedParams,
    handleSearch,
    updateParams,
  } = useQueryParams<LoanClaimsParams>({
    params: new LoanClaimsParams(),
  });

  const claimedColumns: ColumnProps<LoanClaimsModel>[] = [
    {
        title: "Loan ID",
        dataIndex: "loanCode",
        render: (code: string) => fillEmptyData(code?.toUpperCase()),
        sorter: true,
    },
    {
        title: "Name of Expired",
        dataIndex: "nameOfExpired",
        render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: string) => fillEmptyData(toTitleCase(removeUnderscore(role))),
      sorter: true,
    },
    {
        title: "Death Date",
        dataIndex: "deathDate",
        render: (date: string) =>
            dateTimeFormatter(date, DateFormat.DATE),
        sorter: true
    },
    {
      title: "Claim Sent On",
      dataIndex: "claimSentDate",
      render: (_, record: LoanClaimsModel) => {
        return dateTimeFormatter(record.claimSentOn, DateFormat.DATE)
      },
    },
    {
      title: "Claimed Date",
      dataIndex: "claimedDate",
      render: (_, record: LoanClaimsModel) => {
        return dateTimeFormatter(record.claimedDate, DateFormat.DATE)
      },
    },
    {
      title: "Days to Claim",
      dataIndex: "daysToClaim",
      render: (_, record: LoanClaimsModel) => {
        return `${record?.daysToClaim} Days`
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        const className = "active-status--" + record.claimReceivedByCustomer;
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record?.claimReceivedByCustomer ? LoanClaimReceivedStatus.RECEIVED : LoanClaimReceivedStatus.NOT_RECEIVED)}
          </span>
        );
      },
    },
    {
        title: "Branch",
        dataIndex: "branch.name",
        render: (text, record) => {
            return fillEmptyData(record?.branch)
          },
    },
    {
      title: "CRO",
      dataIndex: "cro",
      render: (text: string, record: LoanClaimsModel) => {
        return fillEmptyData(toTitleCase(record?.croName))
      },
    },
    {
      title: "Outstanding",
      dataIndex: "outstanding",
      render: (_, record: LoanClaimsModel) => {
          return currencyFormatter({amount: record.outstanding, hasCurrencySign: true})
      },
      sorter: true
  },
  {
    title: "To be Delivered",
    dataIndex: "toCustomer",
    render: (text: string, record: LoanClaimsModel) => {
      return currencyFormatter({amount: record.toCustomer, hasCurrencySign: true})
    }
  },
  {
    title: "Insurer",
    dataIndex: "insurer",
    render: (_, record: LoanClaimsModel) => fillEmptyData(toTitleCase(removeUnderscore(record?.insurer))),
  },
  {
    title: "Nominee Name",
    dataIndex: "nomineeName",
    render: (_, record: LoanClaimsModel) => fillEmptyData(record.nomineeName),
  },
]

  const { getClaims, loading } = ClaimsService()
  const [totalClaims, setTotalClaims] = useState(1);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loanClaims ?? []
  );
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<LoanClaimsModel>>(claimedColumns);
  const [claims, setClaims] = useState<LoanClaimsModel[]>([]);
  const { updateSortData } = useSorting<LoanClaimsModel>({ sortBy });
  useEffect(() => {
    setColumnConfig(TableConfig.LOAN_CLAIMS, filteredColumns);
  }, [filteredColumns]);

  const {
    visible: claimedSettingsVisible,
    toggleVisibility: toggleClaimedSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<LoanClaimsModel>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<LoanClaimsModel>);
    updateParams({
      ...claimedParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchClaims();
  }, [claimedParams, claimedParams.searchText]);

  const fetchClaims = async () => {
    const updatedParams = {
      ...claimedParams,
      searchText: claimedParams.searchText,
      status: LoansClaimsStatus.CLAIMED
    };
    const claimsData = await getClaims(updatedParams);
    if (claimsData?.claims) setClaims(claimsData?.claims);
    if (claimsData?.meta) {
      setTotalClaims(claimsData?.meta?.totalCount ?? 1);
    }
  };

  return (
    <div className="claimed-claims">
         <div className="claimed-claims-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Document",
            value: claimedParams.searchText,
          }}
          settingsClick={toggleClaimedSettingsVisibility}
        />
      </div>
      <Table
        onChange={handleChange}
        scroll={{ x: true }}
        loading={loading}
        className="claimed-claims-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={claims}
        rowKey={(record: LoanClaimsModel) => record?.loanId as number}
        onRow={(record: LoanClaimsModel) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.INDIVIDUAL_LOAN_CLAIM, {
                loanId: String(record?.loanId),
              })
            );
          },
        })}
        pagination={{
          current: claimedParams?.page,
          hideOnSinglePage: true,
          total: totalClaims,
        }}
      />
      <Drawer
        placement="right"
        visible={claimedSettingsVisible}
        destroyOnClose
        onClose={() => toggleClaimedSettingsVisibility(false)}
        title="Column Options"
        width={500}
        className="claimed-claims__settings-drawer"
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleClaimedSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
    </div>
  );
};

export default ClaimedClaims;

import * as Yup from "yup";
import { precloseConstants } from './constants';

export const preCloseValidation = Yup.object().shape({
  outstandingAmount: Yup.string()
    .required()
    .label("Outstanding Amount is required"),
    amountCollected: Yup.string()
    .required(precloseConstants.FORM_FIELDS.AMOUNT_COLLECTED.REQUIRED_VALIDATION)
    .oneOf(
      [Yup.ref(precloseConstants.FORM_FIELDS.PRECLOSE_AMOUNT.FIELD)],
      precloseConstants.FORM_FIELDS.AMOUNT_COLLECTED.MATCH_VALIDATION
    ),
  precloseAmount: Yup.string().required().label(precloseConstants.FORM_FIELDS.PRECLOSE_AMOUNT.LABEL),
  id: Yup.string().required().label("Id is required"),
  numberOfpendingDues: Yup.string().required().label("No of Dues is required"),
  customerName: Yup.string().required().label("Customer Name is required"),
});

import React, { useEffect } from 'react';
import { Employee } from '../../../../../models/employee.model';
import { EmployeeService } from '../../../../../services/Employee/employee.service';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { getStatusValues } from './utils/getStatusParams';
import { filterStatusOptions } from './utils/filterOptions';
import { userActiveStatusOption } from '../../../../../shared/Constant/userActiveStatusOptions';
import { changeUserStatusConstants } from './constants';
import CustomModal from '../../../../../shared/components/CustomModal';
import { ChangeStatusSchema } from './validations';
import SelectInput from '../../../../../shared/components/SelectInput';
import { Button } from 'antd';
import { ButtonType, HTMLButtonType } from '../../../../../enums/buttonType';
import { Meta } from '../../../../../models/meta.modal';
import { UserStatus } from '../../../../../enums/userStatus';

interface ChangeUserStatusModalProps {
  employee?: Employee;
  handleModalCancel: () => void;
  visible: boolean;
  populateEmployee: () => void;
  onEmployeeUpdate: (employee: Employee) => void;
  onFormValuesChange: (value: string | number) => void;
}

const ChangeUserStatusModal = ({
  employee,
  handleModalCancel,
  visible,
  populateEmployee,
  onEmployeeUpdate,
  onFormValuesChange
}: ChangeUserStatusModalProps) => {
  const { updateEmployeeStatus } = EmployeeService();
  const { userId } = useParams<{ userId: string }>();

  const handleSubmit = async (
    values: Employee,
    { resetForm }: FormikHelpers<Employee>
  ) => {
    const { isActive, isFrozen } = getStatusValues(values.activeStatus as UserStatus);

    const changedStatus = await updateEmployeeStatus({
      assignedForId: Number(userId),
      isActive: isActive,
      isFrozen: isFrozen
    });

    if (changedStatus) onEmployeeUpdate(changedStatus);

    resetForm();
    handleModalCancel();
    populateEmployee();
  };

  const filteredOptions = filterStatusOptions(
    userActiveStatusOption,
    employee?.isActive,
    employee?.isFrozen
  );

  return (
    <CustomModal
      title={changeUserStatusConstants.MODAL_TITLE}
      visible={visible}
      isOkButton={false}
      isCancelButton={false}
      onClose={handleModalCancel}
    >
      <Formik
        initialValues={new Employee()}
        validationSchema={ChangeStatusSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, values, setFieldValue }) => {

          const handleSelect = (name: string, options: Meta | Meta[]) => {
            const option = options as Meta;
            setFieldValue(name, option.value)
            if (option.value) onFormValuesChange(option.value)
          }

          return (
            <Form className="ant-form-vertical">
              <SelectInput
                label={changeUserStatusConstants.STATUS.LABEL}
                name={changeUserStatusConstants.STATUS.FIELD}
                options={filteredOptions}
                placeholder={changeUserStatusConstants.STATUS.PLACEHOLDER}
                onChange={handleSelect}
              />
              <div className="modal__footer text-right">
                <Button
                  htmlType={HTMLButtonType.RESET}
                  onClick={handleModalCancel}
                  className="modal-footer__cancel"
                  type={ButtonType.LINK}
                >
                  {changeUserStatusConstants.BUTTON_TEXT.CANCEL_TEXT}
                </Button>
                <Button
                  className="modal-footer__submit"
                  htmlType={HTMLButtonType.SUBMIT}
                  disabled={!dirty || !isValid}
                >
                  {changeUserStatusConstants.BUTTON_TEXT.CONFIRM_TEXT}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default ChangeUserStatusModal;

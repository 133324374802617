import { Col, Popover, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { DateFormat } from "../../../../../enums/dateFormat.type";
import { LoanStatus } from "../../../../../enums/loanStatus.type";
import { Loan } from "../../../../../models/loan.model";
import {
  capitalizeWord,
  currencyFormatter,
  dateTimeFormatter,
  percentageFormatter,
} from "../../../../../shared/utils/formatter";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import { OptionArray } from "../../../../../shared/types/optionArray.type";
import "./loanDetails.scss";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { convertToOrdinal } from "../../../../../shared/utils/convertToOrdinal";
import NACHLOGO from "../../../../../Assets/images/NACH 1.png";
import iIcon from "../../../../../Assets/images/information_icon.svg";
import { toTitleCase } from "../../../../../shared/utils/titleCaseConverter";
import { getLoanStatusLabel } from "../../../../../shared/utils/getLoanStatusLabel";
import { getBCStatusLabelSwitch } from "../../../../../shared/utils/getBCStatusLabel";
import { BCStatusTags } from "../../../../../enums/currentStatusTags.type";

interface LoanDetailsProps {
  loan?: Loan;
}

const LoanDetails = (props: LoanDetailsProps) => {
  const location = useLocation();
  const [disbursedDate, setDisbursedDate] = useState(
    location?.state?.disbursedDate
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDisbursedAmountPopoverVisible, setIsDisbursedAmountPopoverVisible] =
    useState(false);

  const { loan } = props;
  const dropdownContent = (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div>
          <p className="title">Disbursed Amount Breakup</p>
          <div className="content-container">
            <Col className="disbursement-titles">
              <Row gutter={[24, 24]} className="disbursement-row">
                <Col span={15}>
                  <p className="label">Loan Amount</p>
                </Col>
                <Col>
                  <p className="amount font-600">
                    {loan?.requestedLoanAmt !== undefined
                      ? currencyFormatter({
                        amount: loan.requestedLoanAmt,
                        hasCurrencySign: true,
                      })
                      : "N/A"}
                  </p>
                </Col>
              </Row>
              {loan?.loanDeductions?.map((deduction, index) => (
                <Row key={index} gutter={[24, 16]} className="disbursement-row">
                  <Col span={15}>
                    <p className="label">{removeUnderscore(toTitleCase(capitalizeWord(deduction.name || 'N/A')))}</p>
                  </Col>
                  <Col>
                    <p className="amount">
                      <span className="mr-5">-</span>
                      {deduction.amount !== undefined
                        ? currencyFormatter({
                          amount: deduction.amount,
                          hasCurrencySign: true,
                        })
                        : "N/A"}
                    </p>
                  </Col>
                </Row>
              ))}
              <hr></hr>
              <Row gutter={[24, 16]} className="disbursement-row amount-row">
                <Col span={15}>
                  <p className="label">Disbursed Amount</p>
                </Col>
                <Col>
                  <p className="amount disbursed-amount ml-2">
                    {loan?.disbursedAmount !== undefined
                      ? currencyFormatter({
                        amount: loan.disbursedAmount,
                        hasCurrencySign: true,
                      })
                      : "N/A"}
                  </p>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
  const isLoanDisbursed = loan?.status === LoanStatus.DISBURSED;
  const isLoanRejected = loan?.status === LoanStatus.REJECTED;
  const isLoanPreclosed = loan?.status === LoanStatus.PRE_CLOSED || loan?.status === LoanStatus.PRECLOSED_CLAIMS
  const isMASRejected = loan?.currentStatusTag === BCStatusTags.MASS_REJECTED

  const loanDetails: OptionArray[] = [
    {
      label: "Loan Type",
      value: fillEmptyData(loan?.loanTypeName),
    },
    {
      label: "Loan ID",
      value: fillEmptyData(loan?.code?.toUpperCase()),
    },
    {
      label: "Partner ID",
      value: fillEmptyData(loan?.laf?.toUpperCase()),
    },
    {
      label: "Loan Amount",
      value: currencyFormatter({
        amount: loan?.requestedLoanAmt ?? 0,
        hasCurrencySign: true,
      }),
    },
    {
      label: "Interest",
      value: fillEmptyData(
        percentageFormatter({ value: Number(loan?.interestRate) })
      ),
    },
    {
      label: "Loan Tenure",
      value: loan?.tenureInMonths ? `${loan?.tenureInMonths} Months` : "NA",
    },

    {
      label: "Loan purpose",
      value: fillEmptyData(loan?.loanPurposeName),
    },
    {
      label: "Branch",
      value: fillEmptyData(loan?.customer?.center?.branch?.name),
    },
    {
      label: "CRO Name",
      value: fillEmptyData(loan?.currentCro),
    },
    {
      label: "Applied Date",
      value: dateTimeFormatter(loan?.appliedDate, DateFormat.DATE),
    },
    
    isLoanDisbursed && {
      label: "Disbursed Date",
      value: dateTimeFormatter(loan?.recentLoanStatus?.assignedAt, DateFormat.DATE),
    },
    isLoanDisbursed && {
      label: "Disbursed Amount",
      value: currencyFormatter({
        amount: loan?.disbursedAmount ?? 0,
        hasCurrencySign: true,
      }),
      key: "disbursedAmount",
    },

    isLoanRejected && {
      label: "Reject Reason",
      value: fillEmptyData(loan?.rejectionReason ?? ""),
    },
    {
      label: "Preferred Channel",
      value: fillEmptyData(loan?.organizationName),
    },
    {
      label: "Collection Date",

      value: loan?.collectionDate
        ? `${convertToOrdinal(loan?.collectionDate)} of every month`
        : "NA",
    },
    isLoanPreclosed && {
      label: 'Pre Closed Amount',
      value: currencyFormatter({ amount: loan?.preCloseAmount ?? 0, hasCurrencySign: true })
    },

    isLoanPreclosed && {
      label: 'Pre Closed Date',
      value: dateTimeFormatter(loan?.preCloseDate, DateFormat.DATE),
    },
    isLoanDisbursed && {
      label: "Deductions",
      value: currencyFormatter({
        amount: loan?.totalDeductions ?? 0,
        hasCurrencySign: true,
      }),
    },
    {
      label: "Loan Cycle",
      value: fillEmptyData(loan?.loanCycle)
    },
    isMASRejected && {
      label: "MAS Rejection Reason",
      value: fillEmptyData(loan?.masRejectionReason)
    }
  ].filter(Boolean) as OptionArray[];

  return (
    <Row gutter={[16, 16]} className="block">
      <Col span={24}>
        <span className="block__heading"> loan details</span>
        <span className={`loan-details__badge text-primary ml-5 mr-5`}>
          {removeUnderscore(loan?.status ?? "")}
        </span>
        {loan?.nachEnabled && (
          <img src={NACHLOGO} width={50} height={25} alt="" />
        )}
        {loan?.currentStatusTag && (
        <span className={`loan-details__bc-status text-primary ml-5 mr-5`}>{getBCStatusLabelSwitch((loan?.currentStatusTag ?? ""))}</span>
        )}
      </Col>

      {loanDetails?.map((data, index) => {
        return (
          <>
            {data.label !== "Deductions" && (
              <Col span={4} className="block__label">
                <span> {data?.label}</span>
                <span className="ml-3">
                  {data.label === "Disbursed Amount" && data?.value !== "NA" && (
                    <Popover
                      className="disbursement-amount-popover"
                      content={dropdownContent}
                      trigger={"hover"}
                      open={isDisbursedAmountPopoverVisible}
                      placement="bottomRight"
                      onOpenChange={setIsDisbursedAmountPopoverVisible}
                    >
                      <img
                        src={iIcon}
                        alt="info-icon"
                        className="info-icon"
                        onClick={() => {
                          setIsDisbursedAmountPopoverVisible(
                            !isDisbursedAmountPopoverVisible
                          );
                        }}
                      />
                    </Popover>
                  )}
                </span>
                <div className="block__value text-capitalize break-word">
                  {data?.value}
                </div>
              </Col>
            )}
            {data.label === "Deductions" && (
              <Col span={4} className="block__label">
                {data?.label}

                {data?.value !== "NA" && (
                  <Popover
                    className="deductions-popover ml-3"
                    content={dropdownContent}
                    open={isDropdownVisible}
                    placement="bottomRight"
                    trigger={"hover"}
                    onOpenChange={setIsDropdownVisible}
                  >
                    <img
                      src={iIcon}
                      alt="info-icon"
                      className="info-icon"
                      onClick={() => {
                        setIsDropdownVisible(!isDropdownVisible);
                      }}
                    />
                  </Popover>
                )}

                <div className="block__value text-capitalize">
                  {data?.value}
                </div>
              </Col>
            )}
          </>
        );
      })}
    </Row>
  );
};

export default LoanDetails;

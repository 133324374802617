import { useCallback, useState, useEffect } from "react";

import { QueryParams } from "../../models/query.params.model";
import debounce from "lodash.debounce";
import { removeFalsyKeys } from "../utils/removeFalsyKeys";
import { useLocation, useNavigate } from "react-router-dom";
import { objectToIndexedQueryString } from "../utils/objectToQueryHelper";
import { singleValueQuery } from "../Constant/singleValueQuery";
import { multiValueStringQuery } from "../Constant/mutiValueStringQuery";

interface useQueryParamsProps<T> {
  params: T;
}

const getParams = (customUrl?: string) => {
  const urlParams = new URLSearchParams(customUrl ? new URL(customUrl).search : window.location.search);

  const params: any = {};
  var UrlKeys: any = urlParams.keys();
  for (let key of UrlKeys) {
    const value = singleValueQuery?.includes(key)
      ? urlParams.get(key)
      : urlParams.getAll(key);

    if (!singleValueQuery?.includes(key) && Array.isArray(value)) {
      const fliterArr = value?.map((val) => {
        if (multiValueStringQuery?.includes(key)) return val;
        else return Number(val);
      });
      params[key] = fliterArr;
    } else {
      params[key] = Number(value) || value;
    }
  }

  return params;
};

const useQueryParams = <T extends QueryParams>(
  props: useQueryParamsProps<T>
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { params } = props;

  const [queryParams, setQueryParams] = useState(getParams());

  const updateParams = useCallback((newParams: T, hashValue?: string) => {
    setQueryParams((prev: T) => ({ searchText: prev.searchText, ...newParams }));
    const params = objectToIndexedQueryString(removeFalsyKeys(newParams));

    navigate(
      {
        pathname: location.pathname,
        hash: hashValue ?? location?.hash,
        search: params.toString(),
      },
      { replace: true }
    );
  }, []);

  const switchTabs = useCallback((newParams: T, hashValue?: string) => {
    const updatedParams = { page: 1 };
    const queryString = objectToIndexedQueryString(updatedParams);

    navigate(
      {
        pathname: location.pathname,
        hash: hashValue ?? location?.hash,
        search: queryString,
      },
      { replace: true }
    );
  }, []);

  const hashValue = location.hash

  const handleSearch = debounce((searchText: string) => {
    updateParams({ ...queryParams, page: 1, searchText: searchText || undefined } as any);
    window.location.hash = hashValue
  }, 500);
  

  const removeAllParams = useCallback((hashValue?: string) => {
    setQueryParams({});
    navigate(
      {
        pathname: location.pathname,
        hash: hashValue ?? location?.hash,
        search: "",
      },
      { replace: true }
    );
  }, []);

  return {
    params: queryParams,
    updateParams,
    handleSearch,
    getParams,
    removeAllParams,
    switchTabs
  };
};

export default useQueryParams;

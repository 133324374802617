import { CrifRating } from "../../enums/crifStatus.type";

export const calculateAveragePercentage = (minimum?: number, maximum?: number, decimalPlaces: number = 0) => {
   
    if (!minimum || !maximum) {
        return 0;
    }
    const totalPercentage = (minimum / maximum) * 100;
    return parseFloat(totalPercentage.toFixed(decimalPlaces))};


export const getCrifRating = (rating?: number) => {

    if (!rating) return 
    if (rating < 500) 
        return CrifRating.AVERAGE;
     else if (rating > 500 && rating < 700) 
        return CrifRating.GOOD;
     else 
        return CrifRating.EXCELLENT;
    
}

export const getCrifStatus = (status: number) => {
    if (status < 50) 
        return CrifRating.POOR
    else if (status > 40 && status < 70 )
        return CrifRating.AVERAGE
    else if (status > 70 && status < 90 )
        return CrifRating.GOOD
    else return CrifRating.EXCELLENT
}

export const convertStringForClassName = (string: string | React.ReactNode) => {
    return string?.toString().toLowerCase()
}
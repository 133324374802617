export const modDocumentsConstants = {
    DOWNLOAD_BUTTON_TEXT: "Download Report",
    MODAL_TITLE: (reportName: string) => `Download ${reportName} MOD Loans Report`,
    PENDING: "Pending",
    STORED: "Stored",
    TO_RETURN: "To Return",
    RETURNED: "Returned",
    PENDING_TAG: "#pending",
    TO_RETURN_TAG: "#to-return",
    STORED_TAG: "#stored",
    RETURNED_TAG: "#returned",
    SUBMIT_BUTTON: "Submit",
    CANCEL_BUTTON: "Cancel",
    FORM_CONSTANTS: {
        BRANCH: {
            NAME: "branch",
            PLACEHOLDER: "Select Branches",
            LABEL: "Branch"
        },
        DATE: {
            NAME: "date",
            PLACEHOLDER: "Choose a date range",
            LABEL: "Disbursement Date"
        },
        FROM_DATE: "fromDate",
        TO_DATE: "toDate",
    },
    REPORT_DOWNLOAD_ERROR: "Unable to Download the request report"
}
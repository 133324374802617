
import {serializable, alias, object, list, primitive} from 'serializr';
import { LoanPaymentHistory } from './loanPaymentHistory.model';

export class AccountsDetails { 

	@serializable(alias('reported_on', primitive()))
	reportedOn?: string;

	@serializable(alias('loan_account_number', primitive()))
	accountNumber?: string;

	@serializable(alias('account_holder_type', primitive()))
	accountHolderType?: string;

	@serializable(alias('overdue_amount', primitive()))
	overDueAmount?: number;
	
	@serializable(alias('instalment_frequency', primitive()))
	instalementFrequency?: string;

	@serializable(alias('loan_amount_paid'))
	loanAmountPaid?: number

	@serializable(alias('dpd_days', primitive()))
	daysPastDue?: number;

	@serializable(alias('on_time_payments', primitive()))
	onTimePayments?: number

	@serializable(alias('total_payments'))
	totalPayments?: number;

	@serializable(alias('write_off_amount', primitive()))
	writeOffAmount?: number;

	@serializable(alias('instalment_amount', primitive()))
	instalmentAmount?: number;

	@serializable(alias('last_updated', primitive()))
	lastUpdated?: string;

	@serializable
	status?: string

	@serializable(alias('amount_paid', primitive()))
	amountpaid?: number;

	@serializable(primitive())
	lender?: string;

	@serializable(alias('account_type', primitive()))
	accountType?: string;

	@serializable(alias('account_status', primitive()))
	accountStatus?: string;

	@serializable(alias('loan_credit_years', primitive()))
	loanCreditYears?: number;

	@serializable(alias('loan_credit_months', primitive()))
	loanCreditMonths?: number;

	@serializable(alias('disbursed_amount', primitive()))
	disbursedAmount?: number;

	@serializable(alias('disbursed_date', primitive()))
	disbursedDate?: string;

	@serializable(alias('current_balance', primitive()))
	currentBalance?: number;

	@serializable(alias('last_payment_date', primitive()))
	lastPaymentDate?: string;

	@serializable(alias('closed_date', primitive()))
	closedDate?: string;

	@serializable(primitive())
	weekly?: number;

	@serializable(alias('loan_payment_history', list(object(LoanPaymentHistory))))
	loanPaymentHistory?: LoanPaymentHistory[] = [];

}
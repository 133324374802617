import { Col, Row, Space } from "antd";
import React from "react";
import totalWriteOff from "../../../../../Assets/images/Total Write off.svg";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { WrittenOff } from "../../../../../models/writtenOff.model";
import { CreditReportKeyFactorLabels } from "../constants";

interface TotalWriteOffProps {
  data?: WrittenOff;
}

const TotalWriteOff = (props: TotalWriteOffProps) => {
  const { data } = props;

  const titleCardData: ListMappingKeys[] = [
    {
      label: CreditReportKeyFactorLabels.TOTAL_WRITE_OFF_ACCOUNTS,
      value: data?.totalWriteOffAccounts,
      offset: 6,
      key: "1",
    },
    {
      label: CreditReportKeyFactorLabels.TOTAL_WRITE_OFF_AMOUNT,
      value: currencyFormatter({amount: data?.totalWriteOffAmount, hasCurrencySign: true, shouldReturnNA: false}),
      offset: 6,
      key: "2",
    },
  ];

  return (
    <div>
      <Row className="card crif-card" align={"middle"}>
        <Col span={10}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={totalWriteOff} alt="" />
          </Col>
          <Col>{CreditReportKeyFactorLabels.TOTAL_WRITE_OFF}</Col>
        </Space>
        </Col>
        {titleCardData?.map((data) => (
          <Col span={data.offset} key={data.key} className="flex-column">
            <span className="font-weight__600 font-size__18">
              {data?.value || 0}
            </span>
            <span className="block__label">{data?.label}</span>
          </Col>
        ))}
        <Col className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({amount: data?.totalWriteOffAmount, hasCurrencySign: true, shouldReturnNA: false})}</span>
          </div>
          {/* commenting till confirmation from BE  */}
          {/* <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span> */}
        </Col>
      </Row>
    </div>
  );
};

export default TotalWriteOff;

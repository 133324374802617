import { DocumentExtensions } from "../../enums/DocumentExtensions.type";
import { getFileExtension } from "./getFileExtension";

export const updatedPageOnItemAddition = (itemsCount: number) => {
  const updatedItemsCount = itemsCount + 1;
  //   To sync with item range and page number.
  //   i.e., items 1-10 lies in page 1(not in 0)...
  const itemRange = updatedItemsCount % 10 === 0 ? 0 : 1;

  return Math.floor(updatedItemsCount / 10) + itemRange;
};

function debounce(func: Function, timeout = 300) {
  let timer: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      //@ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}

function arrayMatch(arr1: any[], arr2: any[]) {
  const filteredStrArray = arr1
    .filter((value) => arr2.includes(value))
    .filter((value, index, self) => self.indexOf(value) === index);
  return filteredStrArray;
}

export const compareStringByIgnoringCase = (str1?: string, str2?: string) =>
  str1?.toLowerCase() === str2?.toLowerCase();

export function createOptionsArray (numbers: number[]) {
  const optionsArray = numbers.map(number => {
    const label = `${number} Months`;
    const value = number;
    const code = String(number)

    return { label, value, code };
  });
  return optionsArray;
}

export const isPDF = (endpoint?: string) => {
  if (!endpoint) return false;
  return getFileExtension(endpoint) === DocumentExtensions.PDF;
}


export default { debounce, arrayMatch };

import { modLoansTypes } from "../../../../enums/modLoans.type";
import { modDocumentsConstants } from "../constants"; 

const getModReportParam = (hash: string): string =>
  [
    [modDocumentsConstants.PENDING_TAG, modLoansTypes.PENDING],
    [modDocumentsConstants.RETURNED_TAG, modLoansTypes.RETURNED],
    [modDocumentsConstants.TO_RETURN_TAG, modLoansTypes.TO_RETURNED],
    [modDocumentsConstants.STORED_TAG, modLoansTypes.STORED]
  ].find(([tag]) => hash.includes(tag))?.[1] || "";

export default getModReportParam;
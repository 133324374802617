import { Col, Collapse, Row, Space } from "antd";
import React from "react";
import overDue from "../../../../../Assets/images/OD (Overdue Accounts).png";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { OverdueAccounts } from '../../../../../models/overdueAccounts.model';
import PaymentHistory from "../../../Total Accounts/PaymentTable";
import { AccountsDetails } from "../../../../../models/accountsDetails.model";
import { getStatusClass } from "../../../utils";
import { toTitleCase } from "../../../../../shared/utils/titleCaseConverter";
import { CreditReportKeyFactorLabels } from "../constants";
import { RowAlignment } from "../../../../../enums/rowAlignment.type";
import { Direction } from "../../../../../enums/justify.type";

interface OverDueProps {
  data?: OverdueAccounts;
}

const OverDue = (props: OverDueProps) => {
  const { data } = props;

  const { Panel } = Collapse;

  const overDueData: ListMappingKeys[] = [
    {
      label: CreditReportKeyFactorLabels.TOTAL_OD_ACCOUNTS,
      value: data?.totalOdAccounts,
      offset: 6,
      key: "1"
    },
    {
      label: CreditReportKeyFactorLabels.TOTAL_OD_AMOUNT,
      value: currencyFormatter({amount: data?.totalOdAmount, hasCurrencySign: true, shouldReturnNA: false}),
      offset: 3,
      key: "2"
    },
  ];


  const renderPanelContent = (overDueDetail: AccountsDetails) => {
    const overDuePanelContent: ListMappingKeys[] = [
      {
        label: CreditReportKeyFactorLabels.ACCOUNT_NUMBER,
        value: fillEmptyData(overDueDetail.accountNumber),
        offset: 4,
        key: "1",
      },
      {
        label: CreditReportKeyFactorLabels.DAYS_PAST_DUE,
        value: fillEmptyData(overDueDetail.daysPastDue),
        offset: 4,
        className: "text__label-green font-size__18 font-weight__600",
        key: "2",
      },
      {
        label: CreditReportKeyFactorLabels.OD_AMOUNT,
        value: fillEmptyData(overDueDetail.overDueAmount),
        offset: 4,
        key: "3"
      },
      {
        label: CreditReportKeyFactorLabels.LAST_UPDATED,
        value: fillEmptyData(overDueDetail.lastUpdated),
        offset: 3,
        key: "4",
      },
      {
        label: CreditReportKeyFactorLabels.STATUS,
        value: fillEmptyData(toTitleCase(overDueDetail.status)),
        offset: 3,
        key: "5",
        className: getStatusClass(overDueDetail.status)
      },
    ];

    return (
      <Row className={`card crif-card mt-5`} align={RowAlignment.MIDDLE}>
        <Col span={6}>
        <Space direction={Direction.HORIZONTAL} size={12}>
          <Col>
            <img src={overDue} alt="" />
          </Col>
          <Col className="flex-column">
            <span className="text__value">{overDueDetail.lender}</span>
            <span className="text__label">{overDueDetail.accountType}</span>
          </Col>
        </Space>
        </Col>
        {overDuePanelContent.map((content) => (
          <Col span={content.offset} key={content.key} className="flex-column">
            <span className={`text__value ${content.className}`}>{content.value}</span>
            <span className="text__label">{content.label}</span>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div>
      <Row className="card crif-card" align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={overDue} alt="" />
          </Col>
          <Col>{CreditReportKeyFactorLabels.OD_ACCOUNTS_LABEL}</Col>
        </Space>
        {overDueData.map((data) => {
          return (
            <>
              <Col offset={data.offset} className="flex-column">
                <span className="font-weight__600 font-size__18">
                  {data.value || 0}
                </span>
                <span>{data.label}</span>
              </Col>
            </>
          );
        })}
        <Col offset={3} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({amount: data?.totalOdAmount, hasCurrencySign: true, shouldReturnNA: false})}</span>
          </div>
          {/* Commenting till confirmation from BE */}
          {/* <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span> */}
        </Col>
      </Row>
      <div className="payment-status">
        <Collapse expandIconPosition="end" bordered={false} ghost>
          {data?.overdueAccountDetails?.map((emiDetail, index) => (
            <Panel header={renderPanelContent(emiDetail)} key={index + 1}>
              <PaymentHistory account={emiDetail} />
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default OverDue;

export const changeUserStatusConstants = {
    IMAGE_ALT: "user-image",
    MODAL_TITLE: "Change User Status",

    STATUS: {
        LABEL: "Status",
        FIELD: "activeStatus",
        PLACEHOLDER: "Select Status"
    },

    BUTTON_TEXT: {
        CANCEL_TEXT: "Cancel",
        CONFIRM_TEXT: "Confirm"
    },
    STATUS_VALIDATION_ERROR: "Status is required"
}
import { alias, primitive, serializable } from "serializr";

export class UserAccess {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("user_management", primitive()))
  userManagement?: boolean;
  @serializable(alias("branch_management", primitive()))
  branchManagement?: boolean;
  @serializable(alias("loan_type_management", primitive()))
  loanTypeManagement?: boolean;
  @serializable(alias("customer_configuration", primitive()))
  customerConfiguration?: boolean;
  @serializable(alias("loan_creation", primitive()))
  loanCreation?: boolean;
  @serializable(alias("disbursement", primitive()))
  disbursement?: boolean;
  @serializable(alias("dashboard_management", primitive()))
  dashboardManagement?: boolean;
  @serializable(alias("customer_management", primitive()))
  customerManagement?: boolean;
  @serializable(alias("pipeline_management", primitive()))
  pipelineManagement?: boolean;
  @serializable(alias("loan_management", primitive()))
  loanManagement?: boolean;
  @serializable(alias("repayment_management", primitive()))
  repaymentManagement?: boolean;
  @serializable(alias("report_management", primitive()))
  reportManagement?: boolean;
  @serializable(alias("write_access", primitive()))
  writeAccess?: boolean;
  @serializable(alias("ct_verification_management", primitive()))
  ctVerificationManagement?: boolean;
  @serializable(alias("configuration_management", primitive()))
  configurationManagement?: boolean;
  @serializable(alias('partnerships', primitive()))
  partnershipManagement?: boolean
  @serializable(alias("claim_management", primitive()))
  claimManagement?: boolean
  @serializable(alias("mod_management"))
  modManagement = false
}

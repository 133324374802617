import React, { useEffect, useState } from "react";
import Table from "../../../shared/components/Table";
import { Button, Col, Drawer, TableColumnsType, TableProps } from "antd";
import { Loan, LoanParams } from "../../../models/loan.model";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { SorterResult } from "antd/lib/table/interface";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { TableConfig } from "../../../enums/tableConfig.type";
import './pendingDocuments.scss'
import ColumnOptions from "../../../shared/components/ColumnOptions";
import AcknowledgeDrawer from "../AcknowledgeDetails";
import { pendingColumns } from "./pendingColumns";
import { LoanService } from "../../../services/Loan/loan.service";
import { modLoansTypes } from "../../../enums/modLoans.type";
import MODFilters from "../MODFilters";
import { modDocumentsConstants } from "../DownloadReportModal/constants"; 
import DownloadReportModal from "../DownloadReportModal";

const sortBy = {
  code: "code",
  security: "security",
  customerId: "customer_id",
  customerName: "customer_name",
  createdByFirstName: "created_by.firstname",
  branch: "branch",
  securedType: "secured_type",
  requestedLoanAmt: "requested_loan_amt",
};

const PendingDocuments = () => {
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(pendingColumns);
  const [documents, setDocuments] = useState<Loan[]>([]);
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.modDocuments ?? []
  );
  const [documentsTotalPage, setDocumentsTotalPage] = useState(1);
  const [displayDocument, setDisplayDocument] = useState<Loan>();
  const { loading, getLoans } = LoanService()

  const {
    params: pendingParams,
    updateParams,
    getParams,
    handleSearch: handlePendingSearch,
  } = useQueryParams({
    params: new LoanParams(),
  });

  const {
    visible: documentModalVisible,
    toggleVisibility: toggleDocumentModalVisibility,
  } = useDrawer({});

  const { updateSortData } = useSorting<Loan>({ sortBy });

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...pendingParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const handleUpdateParams = () => updateParams(getParams());

  useEffect(() => {
    setColumnConfig(TableConfig.MOD_OFFICER, filteredColumns);
  }, [filteredColumns]);

  const fetchDocuments = async () => {
    const updatedParams = {
      ...pendingParams,
      searchText: pendingParams.searchText,
      modStatus: modLoansTypes.PENDING
    };
    const loanData = await getLoans(updatedParams);
    if (loanData?.loans) setDocuments(loanData?.loans);
    if (loanData?.meta) {
      setDocumentsTotalPage(loanData?.meta?.totalCount ?? 1);
      loanData?.meta?.filters && setFilters(loanData?.meta?.filters);
    }
  };



  useEffect(()=> {
    fetchDocuments()
  }, [pendingParams])
  
  const {
    visible: pendingFilterVisible,
    toggleVisibility: togglePendingFilterVisibility,
  } = useDrawer({});

  const {
    visible: pendingSettingsVisible,
    toggleVisibility: togglePendingSettingsVisibility,
  } = useDrawer({});

  const { visible: openDownloadModal, toggleVisibility: toggleDownloadModal } = useDrawer({})

  const handleRowChange = (record: Loan) => ({
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        toggleDocumentModalVisibility();
        setDisplayDocument(record);
    },
  });

  const handleDownloadModal = () => {
    toggleDownloadModal(!openDownloadModal);
  };
  
  return (
    <div className="pending-documents">
      {!loading && filters && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <div className="pending-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handlePendingSearch,
            placeholder: "Search Documents",
            value: pendingParams.searchText,
          }}
          filterClick={togglePendingFilterVisibility}
          settingsClick={togglePendingSettingsVisibility}
        />
        <Col offset={1}>
        <Button onClick={handleDownloadModal} className="download-button">{modDocumentsConstants.DOWNLOAD_BUTTON_TEXT}</Button>
        </Col>
      </div>
      <Table
        className={`pending-documents-table`}
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={documents}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: pendingParams?.page,
          total: documentsTotalPage,
          hideOnSinglePage: true,
        }}
      />
      <Drawer
          placement="right"
          onClose={() => togglePendingFilterVisibility(false)}
          visible={pendingFilterVisible}
          width="916"
          title="Filters"
          closable
          destroyOnClose
        >
          <MODFilters
            onClose={() => togglePendingFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
        <Drawer
          placement="right"
          onClose={() => togglePendingSettingsVisibility(false)}
          visible={pendingSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => togglePendingSettingsVisibility(false)}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
        <Drawer
        placement="right"
        onClose={() => toggleDocumentModalVisibility(false)}
        visible={documentModalVisible}
        width={600}
        destroyOnClose
        title="Acknowledge Document"
        >
        <AcknowledgeDrawer fetchDocuments={fetchDocuments} document={displayDocument} onClose={() => toggleDocumentModalVisibility(false)}/>
        </Drawer>
        <DownloadReportModal reportType={modDocumentsConstants.PENDING} visible={openDownloadModal} toggleModal={toggleDownloadModal} />
    </div>
  );
};

export default PendingDocuments;

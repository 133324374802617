export enum UserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  FREEZED = "freezed",
}

export enum UserStatusLabel {
  ACTIVE = "Active",
  IN_ACTIVE = "Inactive",
  FROZEN = "Frozen",
}

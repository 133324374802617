import React from "react";
import "./keyFactor.scss";
import rightArrow from "../../../Assets/images/right-accordion.png";
import { Link } from "react-router-dom";
import { isKeyFactorNameType, switchKeyFactorPage } from "../../../views/CreditReport/KeyFactors/switchKeyFactorPage";
import { KeyFactorConstants } from "./constants";

type KeyFactorProps = {
  name: string;
  icon?: string;
  mainText?: string | number;
  extraMainText?: string;
  className?: string;
  smallText?: string;
  tagText?: string | number;
  creditYear?: number | string;
  creditMonth?: number | string;
  paymentStatus?: string | number;
  loanId?: string;
  loading: boolean
};

const KeyFactor = (props: KeyFactorProps) => {
  const {
    name,
    icon,
    mainText,
    extraMainText,
    className,
    smallText,
    tagText,
    creditMonth,
    creditYear,
    paymentStatus,
    loanId,
  } = props;

  const isValidKeyFactor = loanId && isKeyFactorNameType(name);

  const determineLinkPath = () => {
    if (isValidKeyFactor) {
      return switchKeyFactorPage(name, loanId);
    }
    return "";
  }

  const linkPath = determineLinkPath();
  
  
  return (
    <div className={`key-factor ${className}`}>
      <Link to={linkPath}>
      <div className="container">
        <div className="flex">
        <img alt={KeyFactorConstants.ICON_ALT} src={icon} />
        <p>{name}</p>
        </div>
        <div className="text-container">
          <div className="main-text">
            {(creditYear || creditYear === 0) && (
              <>
                <div className="credit-score-flex">
                  <h3>{creditYear}</h3> <span>Years</span>
                  <h3>{creditMonth}</h3>
                  <span>Months</span>
                </div>
              </>
            )}
            <h3>
              <span>{smallText}</span>
              <span className="main-text-span">{mainText}</span>
            </h3>{" "}
            <span>{extraMainText}</span>
          </div>

          <span className="payment-status">{paymentStatus}</span>
          <div className={`tag-text--${tagText}`}>
            <p>{tagText}</p>
          </div>
        </div>
        <div className="right-arrow">
        <img alt={KeyFactorConstants.RIGHT_ARROW_ALT} src={rightArrow} width={15} height={15} />
        </div>
      </div>
      </Link>
    </div>
  );
};

export default KeyFactor;

import React, { useEffect } from 'react'
import { MetaService } from '../../../services/Meta/meta.service'
import "./paymentReceipt.scss"
import useQueryParams from '../../../shared/hooks/useQueryParams'
const PaymentReceipt = () => {

    const { getReceipt, paymentReceipt } = MetaService()
    const { getParams } = useQueryParams({params: {}})

    useEffect(() => {
          const receiptId = getIdParam()
          getReceipt({ id: Number(receiptId) });
      }, []);

      const getIdParam = () => getParams(getParams().url).id;
      
    return (
        <div className='payment-receipt__container'>
            <iframe
                className="pdf-container__iframe"
                src={paymentReceipt?.receiptLink}
                title="pdf-viewer"
            />
        </div>
    )
}

export default PaymentReceipt
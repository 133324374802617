import axiosInstance from "../../interceptor/axiosInstance";

import { deserialize, serialize } from "serializr";
import { User } from "../../models/user.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { TokenService } from "../TokenService/token.service";
import { RoleName } from "../../enums/roleName.enum";

const AuthService = () => {
  const navigate = useNavigate();
  const { getRemoteAccessToken, removeLocalAccessToken } = TokenService();

  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState(false);

  const { setAuthenticated, removeAuthenticated } = AuthContext();

  const login = async (data: User) => {
    setLoading(true);
    try {
      const { token, employee } = await getRemoteAccessToken(data);
      Notification({
        message: "Login Success",
        type: NotificationTypes.SUCCESS,
      });
      setAuthenticated({ token, employee });
      const isBM = employee?.roleName === RoleName.BRANCH_MANAGER;
      const isAccountant = employee?.roleName === RoleName.ACCOUNTANT;
      const isBranchAccountant = employee?.roleName === RoleName.BRANCH_ACCOUNTANT
      const isMODOfficer = employee?.roleName === RoleName.DOCUMENTATION_OFFICER
      const isInsuranceOfficer = employee?.roleName === RoleName.INSURANCE_OFFICER

      if(isBM) {
        navigate(AppRoutes.CUSTOMERS)
      } else if (isAccountant) {
        navigate(AppRoutes.ACCOUTANT_HOME)
      } else if (isBranchAccountant) {
        navigate(AppRoutes.BRANCH_ACCOUNTANT_HOME)
      } else if (isMODOfficer) {
        navigate(AppRoutes.MOD_DOCUMENTS)
      } else if (isInsuranceOfficer) {
        navigate(AppRoutes.LOAN_CLAIMS)
      } else {
        navigate(AppRoutes.DASHBOARD)
      }
    } catch (ex) {
      Notification({
        message: "Login failed",
        description: "Incorrect Email or Password",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = async (user: User) => {
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.FOGOT_PASSWORD);

      await axiosInstance.post(endpoint, {
        employee: user,
      });

      Notification({
        message: "OTP Sent",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Send OTP",
        description: (ex as Error)?.message ?? "Unable to Send User",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const verifyOTP = async (user: User) => {
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.VERIFY_OTP);

      const { data } = await axiosInstance.post(endpoint, {
        employee: user,
      });

      Notification({
        message: "OTP Verified",
        type: NotificationTypes.SUCCESS,
      });
      const deserializedData = deserialize(User, data?.["employee"]);
      return deserializedData;
    } catch (ex) {
      Notification({
        message: "Unable to VerifydeserializedData OTP",
        description: (ex as Error)?.message ?? "Unable to Verify OTP",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (user: User) => {
    const params = serialize(User, user);
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.RESET_PASSWORD);

      await axiosInstance.post(endpoint, {
        employee: params,
      });

      Notification({
        message: "Password Reset Successful",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Reset Password",
        description: (ex as Error)?.message ?? "Unable to Reset Password",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const resetNewPassword = async (user: User) => {
    const params = serialize(User, user);
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.RESET_NEW_PASSWORD);

      await axiosInstance.put(endpoint, {
        employee: params,
      });

      Notification({
        message: "Password Reset Successful",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Reset Password",
        description: (ex as Error)?.message ?? "Unable to Reset Password",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const sendOtp = async (user: User) => {
    const params = serialize(User, user);
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.SEND_OTP);

      await axiosInstance.post(endpoint, {
        employee: params,
      });

      Notification({
        message: "OTP Sent",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Send OTP",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const verifyEmailOtp = async (user: User) => {
    const params = serialize(User, user);
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.VERIFY_EMAIL_OTP);

      const { data } = await axiosInstance.post(endpoint, {
        employee: params,
      });

      Notification({
        message: "OTP verified",

        type: NotificationTypes.SUCCESS,
      });
      const deserializedData = deserialize(User, data?.["employee"] as User);
      return deserializedData;
    } catch (ex) {
      Notification({
        message: "Unable to verify OTP",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    removeLocalAccessToken();
    removeAuthenticated();
    navigate(AppRoutes.LOGIN, {
      replace: true,
    });
  };

  return {
    error,
    loading,
    login,
    logout,
    resetPassword,
    resetNewPassword,
    forgotPassword,
    verifyOTP,
    sendOtp,
    verifyEmailOtp,
  };
};

export default AuthService;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toTitleCase } from "../../../../shared/utils/titleCaseConverter";
import Back from "../../../../shared/components/Back";
import { Row } from "antd";
import PaymentStatusFactor from "./PaymentStatus";
import { keyFactorRoutes } from "../../../../enums/keyFactors.type";
import MonthlyEMI from "./MonthlyEMI";
import TotalWriteOff from "./TotalWriteOff";
import OverDue from "./OD";
import CreditAge from "./CreditAge";
import TotalSettlements from "./TotalSettlements";
import AccountSummary from "./AccountSummary";
import { getLastSegment } from "../../../../shared/utils/getLastSegment";
import { removeHyphen } from "../../../../shared/utils/removeUnderscore";
import { CrifReport } from "../../../../models/creditReport.model";
import { LocalStorage } from "../../../../enums/localStorage.enum";
import { LocalStorageHelper } from "../../../../shared/utils/localStorageHelper";

const IndividualKeyFactor = () => {
  const location = useLocation();
  const keyFactor: keyFactorRoutes = getLastSegment(location.pathname) as keyFactorRoutes;
  const [crifData, setCrifData] = useState<CrifReport>();
  useEffect(() => {
    const crifData = LocalStorageHelper.getItem(LocalStorage.CRIF)
    if (crifData) setCrifData(crifData)
  }, [])

  const keyFactorContent = {
    [keyFactorRoutes.PAYMENT_STATUS]: (
      <div>
        <PaymentStatusFactor data={crifData} />
      </div>
    ),
    [keyFactorRoutes.MONTHLY_EMI]: <MonthlyEMI data={crifData?.monthlyEmi}/>,
    [keyFactorRoutes.TOTAL_WRITE_OFF]: <TotalWriteOff data={crifData?.writtenOff}/>,
    [keyFactorRoutes.OD]: <OverDue data={crifData?.overdueAccounts}/>,
    [keyFactorRoutes.CREDIT_AGE]: <CreditAge data={crifData}/>,
    [keyFactorRoutes.TOTAL_SETTLEMENTS]: <TotalSettlements data={crifData?.settledAccounts}/>,
    [keyFactorRoutes.ACCOUNT_SUMMARY]: <AccountSummary data={crifData}/>,
  };

  return (
    <div>
      <Back />
      <Row className="mt-5 mb-5">
        <h3 className="block__value">
          {toTitleCase(removeHyphen(keyFactor))}
        </h3>
      </Row>
      {keyFactorContent[keyFactor]}
    </div>
  );
};

export default IndividualKeyFactor;

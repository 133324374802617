import React, { useEffect, useState } from "react";
import { Accountant, AccountantParams } from "../../../../models/accountant.model";
import { ToggleDrawer } from "../../../../shared/types/toggleDrawer";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import useSorting from "../../../../shared/hooks/useSorting";
import { Drawer, Popover, TableColumnsType } from "antd";
import { ColumnProps, TableProps } from "antd/lib/table";
import { fillEmptyData } from "../../../../shared/utils/fillEmptyData";
import useTableConfig from "../../../../shared/hooks/useTableConfig";
import { SorterResult } from "antd/lib/table/interface";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import useDrawer from "../../../../shared/hooks/useDrawer";
import ColumnOptions from "../../../../shared/components/ColumnOptions";
import {
  capitalizeWord,
  dateTimeFormatter,
} from "../../../../shared/utils/formatter";
import { DateFormat } from "../../../../enums/dateFormat.type";
import iIcon from '../../../../Assets/images/iIcon.png'
import docIcon from "../../../../Assets/images/docIcon.png";
import "../../accountant.scss";
import { TableConfig } from "../../../../enums/tableConfig.type";
import RegularisationDetails from "../../RegularisationDetails";
import { LoanStatus } from "../../../../enums/loanStatus.type";
import Table from "../../../../shared/components/Table";
import CustomDocument from "../../../../shared/components/CustomDocument";
import { getBackDateReason } from "../../getBackDateReason";
import { BackDateReason } from "../../../../enums/backDateReason.type";
import TabExtraContent from "../../../../shared/components/TabExtraContent";
import { AccountantService } from "../../../../services/Accountant/accountant.service";

const sortBy = {
  loanCode: "code",
  customerName: "customer_name",
  centerName: "center_name",
  name: "customer_name",
  branch: "branch",
  createdByFirstName: "created_by.firstname",
  dueDate: "due_date",
  entryDate: "entry_date",
  backDate: "back_date",
  status: "status",
  reason: "reason",
  organisations: "organisations",
  organizationName: "organisation_name",
};

const PendingRequests = () => {
  const columns: ColumnProps<Accountant>[] = [
    {
      title: "Loan ID",
      dataIndex: "loanCode",
      render: (code: string) => code?.toUpperCase(),
      sorter: true,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (name: string) => fillEmptyData(name),
      sorter: true,
    },
    {
      title: "CRO",
      dataIndex: "croName",
      render: (name: string) => fillEmptyData(name),
    },
    {
     title: "Branch" ,
     dataIndex: "branch",
     render: (_, record) => fillEmptyData(record?.branch),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      render: (date) => dateTimeFormatter(date ?? "", DateFormat.DATE),
      sorter: true,
    },
    {
      title: "Entry Date",
      dataIndex: "entryDate",
      render: (date) => dateTimeFormatter(date ?? "", DateFormat.DATE),
    },
    {
      title: "Back Date",
      dataIndex: "backDate",
      render: (date) => dateTimeFormatter(date ?? "", DateFormat.DATE),
      sorter: true,
    },
    {
      title: "Reason for Backdate",
      dataIndex: "reason",
      render: (text: string, record: Accountant) => (
        <>
          {fillEmptyData(
            capitalizeWord(getBackDateReason(record.reason ?? ""))
          )}
          {record.reason === BackDateReason.OTHER && (
            <span className="text-bold mb-5">
              <Popover
                content={<div>{capitalizeWord(record?.description ?? "")}</div>}
              >
                <img
                  className="info-icon accountant"
                  src={iIcon}
                  width={15}
                  height={15}
                />
              </Popover>
            </span>
          )}
        </>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (_, record) => {
        const className =
          "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getLoanStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      title: "Collection Proof",
      dataIndex: "collectionProof",
      render: (_, record) => {
        return record?.attachment ? (
          <>
            <div
              className="collection-proof-container"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="text-container">
                <span>
                  <img src={docIcon} alt="" className="proof-icon ml-1" />
                </span>
                <CustomDocument
                  label="Collection-Proof"
                  src={record?.attachment}
                  onlyText={true}
                  lazyLoad
                />
              </div>
            </div>
          </>
        ) : (
          "-"
        );
      },
    },
  ];
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Accountant>>(columns);

  const { getRepaymentRegularisations } = AccountantService();
  const { params, updateParams, handleSearch: handleAccountantSearch, } = useQueryParams({
    params: new AccountantParams()
  });
  const [loans, setLoans] = useState<Accountant[]>([]);
  const [loanTotalPage, setLoanTotalPage] = useState(1);
  const [displayLoan, setDisplayLoan] = useState<Accountant>();
  const { updateSortData } = useSorting<Accountant>({ sortBy });
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.accountant ?? []
  );

  const {
    visible: accountantSettingsVisible,
    toggleVisibility: accountantSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<Accountant>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Accountant>
    );
    updateParams({ ...params, page: current, sortBy, sortDirection });
  };

  const {
    visible: regularisationModalVisible,
    toggleVisibility: toggleregularisationModalVisibility,
  } = useDrawer({});

  const handleRowChange = (record: Accountant) => ({
    onClick: (e: any) => {
      {
        e.preventDefault();
        e.stopPropagation();
        toggleregularisationModalVisibility();
        setDisplayLoan(record);
      }
    },
  });

  const fetchLoans = async () => {
    const status = [LoanStatus.PENDING]
    const loanDetails = await getRepaymentRegularisations({ ...params, status });
    if (loanDetails) {
      setLoans(loanDetails.loans);
        setLoanTotalPage(loanDetails.meta?.totalCount ?? 1);
    }
    }


  useEffect(() => {
    fetchLoans();
  }, [params]);

  useEffect(() => {
    setColumnConfig(TableConfig.ACCOUNTANT, filteredColumns);
  }, [filteredColumns]);

  const handleOnClose = () => {
    toggleregularisationModalVisibility(false);
  };

  return (
    <div className="accountant-section pending-requests">
        <div className="extra-content">
         <div className="d-flex">
            <TabExtraContent
              searchProps={{
                onSearch: handleAccountantSearch,
                placeholder: "Search Loan",
                value: params.searchText,
              }}
              settingsClick={accountantSettingsVisibility}
            />
          </div>
          </div>
      <Table
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loans}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: params?.page,
          total: loanTotalPage,
          hideOnSinglePage: true,
        }}
      />
      <Drawer
        placement="right"
        visible={accountantSettingsVisible}
        destroyOnClose
        onClose={() => accountantSettingsVisibility(false)}
        title="Column Options"
        width={500}
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => accountantSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
      <Drawer
        placement="right"
        width={"40vw"}
        visible={regularisationModalVisible}
        destroyOnClose
        title="Collection Regularisation Request"
        onClose={() => toggleregularisationModalVisibility(false)}
      >
        <RegularisationDetails loan={displayLoan} onClose={handleOnClose} fetchLoans={fetchLoans}/>
      </Drawer>
    </div>
  );
};

export default PendingRequests;

export enum CrifRating {
    POOR = "Poor",
    GOOD = "Good",
    EXCELLENT = "Excellent",
    AVERAGE = "Average",
}

export enum CrifFetchStatus {
    FETCHED = "fetched",
    FAILED = "failed",
}
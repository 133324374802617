import { Col, Collapse, Row, Space } from "antd";
import React from "react";
import monthlyEMI from "../../../../../Assets/images/Monthly EMI.svg";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { MonthlyEmi } from "../../../../../models/monthlyEmi.model";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { AccountsDetails } from "../../../../../models/accountsDetails.model";
import { getStatusClass } from "../../../utils";
import { toTitleCase } from '../../../../../shared/utils/titleCaseConverter';
import { CreditReportKeyFactorLabels } from "../constants";

interface MonthlyEMIProps {
  data?: MonthlyEmi;
}

const MonthlyEMI = (props: MonthlyEMIProps) => {
  const { data } = props;

  const { Panel } = Collapse

  const renderPanelContent = (emiDetail: AccountsDetails) => {
    const overDuePanelContent: ListMappingKeys[] = [
      {
        label: CreditReportKeyFactorLabels.ACCOUNT_NUMBER,
        value: fillEmptyData(emiDetail.accountNumber),
        offset: 5,
        key: "1",
      },
      {
        label: CreditReportKeyFactorLabels.WEEKLY,
        value: fillEmptyData(emiDetail.weekly),
        offset: 4,
        className: "text__label-green font-size__18 font-weight__600",
        key: "2",
      },
      {
        label: CreditReportKeyFactorLabels.LAST_UPDATED,
        value: fillEmptyData(emiDetail.lastUpdated),
        offset: 5,
        key: "3",
      },
      {
        label: CreditReportKeyFactorLabels.STATUS,
        value: fillEmptyData(toTitleCase(emiDetail.status)),
        key: "4",
        className: getStatusClass(emiDetail.status),
      },
    ];

    return (
      <Row className={`card crif-card mt-5`} align={"middle"}>
        <Col span={7}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={monthlyEMI} alt="" />
          </Col>
          <Col className="flex-column">
            <span className="text__value">{emiDetail.lender}</span>
            <span className="text__label">{emiDetail.accountType}</span>
          </Col>
        </Space>
        </Col>
        {overDuePanelContent.map((content) => (
          <Col span={content.offset} key={content.key} className="flex-column">
            <span className={`text__value ${content.className}`}>{content.value}</span>
            <span className="text__label">{content.label}</span>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div>
      <Row className="card crif-card" align={"middle"}>
        <Col span={11}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={monthlyEMI} alt="" />
          </Col>
          <Col>{CreditReportKeyFactorLabels.MONTHLY_EMI}</Col>
        </Space>
        </Col>
        <Col span={10} className="flex-column">
          <span className="font-weight__600 font-size__18">{data?.monthlyEmis || 0}</span>
          <span>{CreditReportKeyFactorLabels.MONTHY_EMI_LOANS}</span>
        </Col>
        <Col className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({ amount: data?.totalMonthlyEmiAmount, hasCurrencySign: true, shouldReturnNA: false })}</span>
            <span className="text-secondary">{CreditReportKeyFactorLabels.PER_MONTH}</span>
          </div>
          {/* commenting till confirmation from BE  */}
          {/* <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span> */}
        </Col>
      </Row>
      <div className="payment-status">
        <Collapse collapsible="icon" expandIconPosition="end" bordered={false} ghost>
          {data?.monthlyEmiAccounts?.map((emiDetail, index) => (
            <Panel showArrow={false} header={renderPanelContent(emiDetail)} key={index + 1}>
              {/* To be checked from BE then uncomment it*/ }
              {/* <PaymentHistory account={emiDetail} /> */}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default MonthlyEMI;

import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";

import { Branch } from "../../../../models/branch.model";
import { Button } from "antd";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { Meta } from "../../../../models/meta.modal";
import { MetaService } from "../../../../services/Meta/meta.service";
import SelectInput from "../../../../shared/components/SelectInput";
import { branchDetailValidation } from "./branchDetailValidation";
import { StepStatus } from "../../../../enums/stepStatus.enum";
import "../branchForm.scss";
import { branchDetailFormConstants } from "./constants";

interface BranchDetailFormProps {
  formValues?: Branch;
  currentStep?: number;
  toggleStep?: (status: string) => void;
  onSubmit: (branch: Branch) => void;
  onClose: () => void;
}

function BranchDetailForm({
  formValues,
  onClose,
  toggleStep,
  onSubmit,
  currentStep,
}: BranchDetailFormProps) {
  const {
    loading: metaLoading,
    getStates,
    getCities,
    getRegions,
    getPreferredChannel,
  } = MetaService();

  const [regions, setRegions] = useState<Meta[]>([]);

  const [states, setStates] = useState<Meta[]>([]);

  const [cities, setCities] = useState<Meta[]>([]);

  const [organizations, setOrganizations] = useState<Meta[]>([]);

  const populateCities = async (stateId?: string) => {
    if (!stateId) return;

    const cities = await getCities(stateId);
    if (cities) setCities(cities);
  };
  useEffect(() => {
    const populateMeta = async () => {
      const regions = await getRegions();
      const states = await getStates();
      const organizations = await getPreferredChannel();

      if (regions) setRegions(regions);
      if (states) setStates(states);
      if (organizations) setOrganizations(organizations);
      if (formValues) populateCities(formValues?.address?.stateId);
    };

    populateMeta();
  }, []);

  const handleSubmit = (values: Branch) => {
    onSubmit(values);
    toggleStep && toggleStep(StepStatus.NEXT);
  };

  return (
    <div>
      <Formik
        initialValues={formValues ?? new Branch()}
        validationSchema={branchDetailValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, isValid, dirty }) => {
          return (
            <div className="branch-detail-form">
              <div className="branch-detail-form__header">
                <div className="branch-detail-form__header__process-count">
                  1
                </div>
                <div className="branch-detail-form__header__process-title">
                  branch details
                </div>
              </div>
              <div className="branch-detail-form__content">
                <Form className="ant-form-vertical">
                  <InputField
                    type={InputType.TEXT}
                    name="name"
                    placeholder="Branch Name"
                    label="Branch Name"
                  />
                  <InputField
                  type={InputType.TEXT}
                  name="code"
                  placeholder="Branch ID"
                  label="Branch ID"
                  />
                  <InputField
                  type={InputType.NUMBER}
                  name={branchDetailFormConstants.DISTANCE.NAME}
                  placeholder={branchDetailFormConstants.DISTANCE.PLACEHOLDER}
                  label={branchDetailFormConstants.DISTANCE.LABEL}
                  />
                  <SelectInput
                    name="regionId"
                    options={regions}
                    placeholder="Select Regions"
                    label="Region"
                  />
                  <InputField
                    type={InputType.EMAIL}
                    name="email"
                    placeholder="Enter Email"
                    label="Email"
                  />
                  <InputField
                    type={InputType.TEXT}
                    name="mobileNumber"
                    placeholder="Phone Number"
                    label="Phone Number"
                  />
                  <InputField
                    type={InputType.TEXT}
                    name="address.addressLine1"
                    placeholder="Address line 1"
                    label="Address line 1"
                  />
                  <InputField
                    type={InputType.TEXT}
                    name="address.addressLine2"
                    placeholder="Address line 2"
                    label="Address line 2"
                  />
                  <SelectInput
                    name="address.stateId"
                    options={states}
                    placeholder="Select States"
                    label="State"
                    onChange={async (_, option) => {
                      const stateId = (option as Meta).value
                        ? String((option as Meta).value)
                        : "";
                      populateCities(stateId);
                    }}
                  />
                  <SelectInput
                    name="address.cityId"
                    options={cities}
                    placeholder="Select Cities"
                    label="City"
                  />
                  <InputField
                    type={InputType.TEXT}
                    name="address.pincode"
                    placeholder="Pincode"
                    label="Pincode"
                  />
                  <InputField
                    type={InputType.TEXT}
                    name="address.landmark"
                    placeholder="Landmark"
                    label="Landmark"
                  />
                  <SelectInput
                    name="organisationId"
                    options={organizations}
                    placeholder="Select Preferred Channels"
                    label="Preferred Channel"
                  />
                  <div className="branch-form__content__footer drawer-footer">
                    <Button
                      className="drawer-footer__cancel"
                      htmlType="reset"
                      onClick={onClose}
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!isValid}
                      className="drawer-footer__submit"
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
export default BranchDetailForm;
